/* eslint-disable jsx-a11y/anchor-is-valid */
import './index.css'
import { FC, useEffect, useState, useContext, useMemo, useCallback, CSSProperties } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import moment from 'moment'
import PoiFormWrapper from '../poi-component/PoiForm'
import { useParams } from 'react-router-dom'
import { TransportsContext } from '../../../context/transports.context'
import '../../../_metronic/assets/sass/plugins.scss'
import '../autonom.scss'
import { EASYTRACK_API_URL } from '../../../constants/api.constants'
import easytrackAxios from '../../../setup/easytrack.axios'
import { UserContext } from '../../../context/user.context'
import { toast } from 'react-toastify'
import Select from '../../../_metronic/layout/components/select/Select'
import { LoadingCard } from '../../../_metronic/layout/components/loading/Loading'
import GeolocationSelect from '../../../_metronic/layout/components/geolocation-select/GeolocationSelect'
import {
  getRouteDetails,
  getEasyTrackZone,
  getPlaceDetails,
  getDislocationType,
  DislocationType,
} from '../../../utils/geolocation.utils'
import { getSortedList } from '../../../utils/sort.utils'
import { ClientContext } from '../../../context/client.context'
import { fields } from './RequestFields'
import { defaultTemplate } from '../../../data/template.default'
import { VehiclesContext } from '../../../context/vehicles.context'
import { CompanyContext } from '../../../context/company.context'
import {
  POI,
} from '../poi-component/poiConstants'

import RepeatRequestField from '../../../_metronic/layout/components/fields/repeatRequest/repeat-request-field'
import { TemplatesContext } from '../../../context/templates.context'
import { capitalize, getURLFormattedTemplateName } from '../../../utils/string.utils'
import CustomInputField from '../../../_metronic/layout/components/fields/customInput/custom-input-field'
import { generateField } from '../../../components/field/field-generator'
import { areObjectsEqual, epsilon, updateField } from '../../../utils/state.utils'
import { OptionalCourseMapWrapper } from './components/CourseMap'
import ModalPortal from '../../../_metronic/layout/components/modal/modal-portal'
import {
  PassengersObjectType,
  convertFieldName,
  extractBaseCurrency,
  getIntermPointsList,
  getTransportTypeString,
  getVoucherFromCompany,
} from './utils'
import _, { debounce } from 'lodash'
import { getTransportForRepeat, getTransportForReturn, getUpdatedTransportWithRentExchangeRate } from '../../../utils/transport.utils'
import ConfirmModalWrapper from './ConfirmModal'
import {
  BreakdownObjectType,
  emptyTariffInfo,
  extractTariffInfo,
  getCurrencyOfTotal,
  getExchangeRate,
  getPriceFormatted,
  getPriceFullyConverted,
  hasIncludedVATByPaymentType,
  TariffInfoType,
  TypeOfCompany,
} from '../../../utils/pricing.utils'
import BreakdownCardWrapper from '../view-transport/modules/BreakdownCard'
import CurrencyInput from '../../../_metronic/layout/components/fields/currencyInput/CurrencyInput'
import { TariffServiceType } from '../../../pages/add-edit-plan/modules/PlanTypes'
import TransportDocumentWrapper from './components/TransportDocumentWrapper'
import { disabledStyle } from '../../../utils/style.utils'
import SelectAsync from '../../../_metronic/layout/components/select/SelectAsync'
import { getCompanyRequest } from '../../../setup/axios/company.request'
import { AppContext } from '../../../context/app.context'
import { defaultRequestStatus, defaultPaymentStatus, defaultTransportType, extractPassengerNames, getDriverKm, getEditedTransportWithCompanyInfo, getReplacementEstimateDateFromTransport, getServiceInfoByName, getTransportInfoByServiceChange, getTransportInfoByTypeChange } from './scripts/transportDataUtils'
import { RouteDetailsType, TransportPricingContextType, convertStringToTransferAction, getBlockedStateFromTransport, getPriceByTariffInfo, transportFieldsTariff, updateTariffInfo, updateTariffInfoRent, updateTransportWithNewRouteInfo } from './scripts/tariffUtils'
import { AppFlowData, ExternalTransportData, IdentificationData, InitialTransportData, RecomputeStatus, SubmitFormProps, isFormValid, submitForm } from './scripts/requestFormOperations'
import { Card, Field, TemplateInfoType, getTemplateInfoObject } from './scripts/structuralFields'
import SearchSubscriptionsField from './components/subscriptions/SearchSubscriptionsField'
import { SubscriptionType } from '../../../types/subscription.types'
import SubscriptionsDisplayField from './components/subscriptions/SubscriptionsDisplayField'
import { RentPlanVehicleClassModel } from '../rent-plans/models/RentPlanVehicleClassModel'
import { RentPlanCompleteInsuranceModel } from '../rent-plans/models/RentPlanInsuranceModel'
import { getRentPlanByClientId, getRentPlanExtraCosts, getRentPlanInsurancesComplete, getRentPlanVehicleClass, getTransportRentInfo } from '../../../setup/axios/rent.request'
import OptionsSelectWrapper, { ExtraType, SelectedExtraType } from './components/rent/OptionsSelect'
import { getDifferenceInDays } from '../../../utils/date.utils'
import { insuranceTypeList } from '../rent-pricing/RentPricingWrapper'
import { TransportType } from './scripts/transportTypes'

interface customFieldObj {
  label?: string
  preFilled?: boolean
  mandatory?: boolean
}

function AddEditTransportPage({
  templateName,
  setExtraTitle,
  companies,
  suppliers,
  departments,
  dictionaries,
  drivers,
  tariffServices,
  tariffTypes,
  templateInfo,
  VAT,
  vehicles,
  vehicleBrands,
  vehicleTypes,
  exchangeRate,
  transportIdForAction,
  action,
}: {
  templateName: string
  setExtraTitle: any
  companies: any[]
  suppliers: any[]
  departments: any[]
  dictionaries: any
  drivers: any[]
  tariffServices: TariffServiceType[]
  tariffTypes: any[]
  templateInfo: TemplateInfoType
  VAT: number
  vehicles: any[]
  vehicleBrands: any[]
  vehicleTypes: any[]
  exchangeRate: number
  transportIdForAction: string
  action: string
}) {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [whichBreakdownChanged, setWhichBreakdownChanged] = useState<{
    tariffChanged: boolean
    commissionChanged: boolean
  }>({
    tariffChanged: false,
    commissionChanged: false,
  })
  const [tariffInfo, setTariffInfo] = useState<TariffInfoType>(emptyTariffInfo)
  const { getTransportCodes } = useContext<any>(ClientContext)
  const { templates } = useContext<any>(TemplatesContext)
  const { getTransports } = useContext<any>(TransportsContext)
  const { businessProfileId } = useContext<any>(UserContext)
  const { getCompanyInfo, companyInfo } = useContext<any>(CompanyContext)
  const { getAllVehicles } = useContext<any>(VehiclesContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)

  const [transport, setTransport] = useState<TransportType | null>(null)
  const [initialCompanyId, setInitialCompanyId] = useState<number>(0)
  const [intermPOIList, setIntermPOIList] = useState<POI[]>([])
  const [pois, setPois] = useState<any[]>([])
  const [repeatOnDates, setRepeatOnDates] = useState<any[]>([])
  const history = useHistory()
  const { id } = useParams<any>()
  const isTransportEmpty = !Boolean(transport)
  const [firstLoadingTransport, setFirstLoadingTransport] = useState<boolean>(true)
  const [triggerByFieldCompanyChange, setTriggerByFieldCompanyChange] = useState<boolean>(false)
  const [triggerCheckPaymentType, setTriggerCheckPaymentType] = useState<boolean>(false)

  const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([])
  const [checkedSubscription, setCheckedSubscription] = useState<SubscriptionType | null>(null)

  const [formattedTariff, setFormattedTariff] = useState<string>('0.00')
  const [initialTariff, setInitialTariff] = useState<number>(0)
  const [formattedCommission, setFormattedCommission] = useState<string>('0.00')
  const [initialCommission, setInitialCommission] = useState<number>(0)

  const [discount, setDiscount] = useState<string>('0')

  const [selectedPlanId, setSelectedPlanId] = useState<number>(0)
  const [rentCurrency, setRentCurrency] = useState<string | null>(null)

  const [vehicleClasses, setVehicleClasses] = useState<RentPlanVehicleClassModel[]>([])
  const [selectedVehicleClassId, setSelectedVehicleClassId] = useState<number>(0)

  const [extraCosts, setExtraCosts] = useState<ExtraType[]>([])
  const [selectedExtraCosts, setSelectedExtraCosts] = useState<SelectedExtraType[]>([])

  const [dislocationType, setDislocationType] = useState<DislocationType | null>(null)

  const [numOfRentalDays, setNumOfRentalDays] = useState<number>(0)

  const [insurances, setInsurances] = useState<RentPlanCompleteInsuranceModel[]>([])
  const [selectedInsuranceId, setSelectedInsuranceId] = useState<number>(0)
  const [insuranceType, setInsuranceType] = useState<string[]>([])
  const filteredInsurances = (insurances || []).filter((insurance) => Number(insurance.plan_vehicle_class_id) === Number(selectedVehicleClassId))

  const isTransportRent: boolean = transport?.type === "RENT"

  const changePaymentTypeEffect = (paymentType: string) => {
    let futureTariff = parseFloat('' + (transport?.tariff || '0'))
    let futureCommission = parseFloat('' + (transport?.commission || '0'))
    const hadIncludedVAT = hasIncludedVATByPaymentType(transport?.paymentType)
    const baseCurrency = extractBaseCurrency({
      transport,
      tariffInfo,
      companyType: TypeOfCompany.Client,
    })
    const futureCurrency = isTransportRent ? (rentCurrency || baseCurrency) : baseCurrency
    const { currency, includeVAT } = getCurrencyOfTotal({
      paymentType,
      currency: futureCurrency,
    })

    if (transport?.currency === 'EUR' && currency === 'RON') {
      futureTariff *= tariffExchangeRate
    } else if (transport?.currency === 'RON' && currency === 'EUR') {
      futureTariff /= tariffExchangeRate
    }
    if (!hadIncludedVAT && includeVAT) {
      futureTariff *= VAT
    } else if (hadIncludedVAT && !includeVAT) {
      futureTariff /= VAT
    }

    setFormattedTariff(getPriceFormatted(futureTariff))
    setFormattedCommission(getPriceFormatted(futureCommission))
    return setTransport((transport: TransportType) => ({
      ...transport,
      currency: currency,
      paymentType: paymentType,
      tariff: futureTariff,
      commission: futureCommission,
    }))
  }

  useEffect(() => {
    if (isTransportRent) {
      const startDateTime = new Date(transport?.effectiveDate).getTime();
      const endDateTime = new Date(transport?.completeEstimateDate).getTime();
      if (startDateTime >= endDateTime) {
        toast.warning("The end date should be greater than the start date!")
        setNumOfRentalDays(0)
      } else if (transport?.effectiveDate && transport?.completeEstimateDate) {
        const duration = Math.ceil(getDifferenceInDays(transport.effectiveDate, transport.completeEstimateDate))
        setNumOfRentalDays(duration)
      }
    }
  }, [transport?.effectiveDate, transport?.completeEstimateDate, isTransportRent])

  const resetSelectedRentState = () => {
    setSelectedExtraCosts([])
    setSelectedInsuranceId(0)
    setInsuranceType([])
    setSelectedVehicleClassId(0)
  }

  useEffect(() => {
    const getAndSetDataForSelectedPlan = () => {
      if (!selectedPlanId) {
        return;
      }
      Promise.allSettled([
        (async () => {
          const futureVehicleClasses = await getRentPlanVehicleClass(selectedPlanId)
          setVehicleClasses(futureVehicleClasses || [])
        })(),
        (async () => {
          const futureExtraCosts = await getRentPlanExtraCosts(selectedPlanId)
          setExtraCosts(getSortedList({ listToSort: futureExtraCosts || [], args: ["name"] }))
        })(),
        (async () => {
          const futurePlanInsurance = await getRentPlanInsurancesComplete(selectedPlanId)
          setInsurances(futurePlanInsurance || [])
        })(),
      ])
    }

    getAndSetDataForSelectedPlan()
  }, [selectedPlanId])

  const [passengers, setPassengers] = useState<PassengersObjectType>({
    mainPassenger: '',
    otherPassengers: [],
  })

  const commissionCurrency = transport?.supplier?.currency || 'RON'
  const isRepeatMode = action === 'repeat'
  const isEditMode = Boolean(id)
  // if the request already exists or it is repeat mode, we should prompt the recalculate modal
  const canPromptRecalculate = isRepeatMode || isEditMode

  useEffect(() => {
    if (Boolean(transport?.passengerName)) {
      setPassengers(extractPassengerNames(transport?.passengerName || ''))
    }
  }, [transport?.passengerName]) // it will trigger only twice - when null and when recieving a value

  const setPassengerNames = useCallback(
    ({ mainPassenger, otherPassengers }: PassengersObjectType) => {
      const newPassengers = extractPassengerNames(mainPassenger)
      const newMainPassenger = newPassengers.mainPassenger
      const newOtherPassengers = [...otherPassengers, ...newPassengers.otherPassengers]

      setPassengers({ mainPassenger: newMainPassenger, otherPassengers: newOtherPassengers })
      setTransport((oldTransport: TransportType) => ({
        ...oldTransport,
        passengers: 1 + newOtherPassengers.length,
      }))
    },
    []
  )

  const voucher = useMemo(
    () => getVoucherFromCompany({ transportCompany: transport?.company, companies }),
    [transport?.company, companies]
  )

  const tariffExchangeRate = useMemo(
    () => {
      const futureExchangeRate = getExchangeRate({
        priceDetails: transport?.priceDetails,
        exchangeRate,
        isTransportRent
      })
      return futureExchangeRate
    },
    [transport?.priceDetails?.exchangeRate, exchangeRate]
  )


  const commissionExchangeRate = useMemo(
    () => getExchangeRate({
      priceDetails: transport?.priceDetails,
      exchangeRate,
      companyType: TypeOfCompany.Supplier,
      isTransportRent
    }),
    [transport?.priceDetails?.commissionExchangeRate, exchangeRate]
  )

  const getPriceByTariffInfoPopulated = useCallback(({
    transport,
    tariffInfo,
    companyType
  }: {
    transport: TransportType,
    tariffInfo: TariffInfoType,
    companyType?: TypeOfCompany
  }) => {
    return getPriceByTariffInfo({
      transport, tariffInfo, companyType,
      commissionCurrency, tariffExchangeRate, commissionExchangeRate, VAT
    })
  }, [commissionCurrency, tariffExchangeRate, commissionExchangeRate, VAT])

  const tariffServiceDict = useMemo(() => {
    let supplierObjects = {}
    let futureDict = {}
      ; (tariffServices || []).forEach((tariffService: TariffServiceType) => {
        if (
          !Boolean(tariffService?.id) ||
          (!Boolean(tariffService?.supplier?.id) && !Boolean(tariffService?.revenueCenter))
        ) {
          return
        }
        const supplierId = Number(tariffService?.supplier?.id)
        if (!supplierObjects[supplierId]) {
          supplierObjects[supplierId] = suppliers.find((supplier: any) => Number(supplier?.id) === supplierId) || {}
        }

        const futureSupplier = {
          ...(tariffService?.supplier || {}),
          currency: supplierObjects[supplierId]?.currency || 'RON'
        }
        futureDict[tariffService?.id] = {
          supplier: futureSupplier,
          revenueCenter: tariffService?.revenueCenter,
        }
      })
    return futureDict
  }, [tariffServices])
  const rentACarService = useMemo(() => getServiceInfoByName(tariffServices, 'rent a car'), [tariffServices])
  const standardService = useMemo(() => getServiceInfoByName(tariffServices, 'standard'), [tariffServices])

  const setTariffDataWithNewInfo = (
    futureTariffInfo: TariffInfoType,
    routeDetailsInfo?: RouteDetailsType
  ) => {
    setTariffInfo(futureTariffInfo)
    let taxObject = getPriceByTariffInfoPopulated({ transport, tariffInfo: futureTariffInfo })
    let commissionObject = getPriceByTariffInfoPopulated({
      transport,
      tariffInfo: futureTariffInfo,
      companyType: TypeOfCompany.Supplier,
    })
    setTransport((oldTransport: TransportType) => ({
      ...oldTransport,
      taxBreakdownTransferType: futureTariffInfo?.transferType,
      taxBreakdownOriginAgency: futureTariffInfo?.originAgency,
      tariff: taxObject.price || 0,
      commission: commissionObject.price || 0,
      ...routeDetailsInfo,
    }))
    setFormattedTariff(taxObject.formattedPrice)
    setFormattedCommission(commissionObject.formattedPrice)
    setTriggerCheckPaymentType(true)
  }

  const rentPlanData = {
    plan_id: selectedPlanId,
    vehicle_class_id: selectedVehicleClassId,
    start_date: transport?.effectiveDate,
    end_date: transport?.completeEstimateDate,
    insurance_id: selectedInsuranceId,
    liability_type: insuranceType,
    extra_costs: selectedExtraCosts,
    dislocation_type: dislocationType,
    exchange_rate: tariffExchangeRate,
    discount: parseInt(discount) / 100,
    distance: transport?.distance,
    currency: rentCurrency || 'RON',
    pickup_time: transport?.effectiveDate ? moment(transport?.effectiveDate).format('HH:mm') : null,
    delivery_time: transport?.completeEstimateDate ? moment(transport?.completeEstimateDate).format('HH:mm') : null,
  }

  const debouncedUpdateTariffInfo = useMemo(() => {
    const debouncedFunction = debounce(updateTariffInfo, 600, {
      leading: false,
    })
    return debouncedFunction
  }, [])

  const debouncedUpdateTariffInfoRent = useMemo(() => {
    const debouncedFunction = debounce(updateTariffInfoRent, 600, {
      leading: false,
    })
    return debouncedFunction
  }, [])

  const populatedUpdateTariffCall = ({
    overwrite = false,
    routeDetailsInfo = {},
    updateBaseCurrency = false,
  }: {
    overwrite?: boolean
    routeDetailsInfo?: RouteDetailsType
    updateBaseCurrency?: boolean
  }) => {
    const baseTariffInfoPayload = {
      transport,
      firstLoadingTransport,
      tariffInfo,
      setTariffDataWithNewInfo,
      overwrite,
      routeDetailsInfo,
      updateBaseCurrency,
      setIsFormLoading
    }
    isTransportRent ?
      debouncedUpdateTariffInfoRent({
        ...baseTariffInfoPayload,
        rentPlanData,
        allExtraCosts: extraCosts,
      }) :
      debouncedUpdateTariffInfo({
        ...baseTariffInfoPayload,
        voucher,
        intermPOIList,
        businessProfileId,
      })
  }

  const debouncedUpdateRouteCall = useMemo(() => {
    const debouncedFunction = debounce(updateTransportWithNewRouteInfo, 600, {
      leading: false,
    })
    return debouncedFunction
  }, [])

  const populatedTransportRouteCall = () => {
    const updateTariffCall = async ({
      overwrite = false, routeDetailsInfo = {}, updateBaseCurrency = false
    }: {
      overwrite?: boolean, routeDetailsInfo?: RouteDetailsType; updateBaseCurrency?: boolean;
    }) => {
      const baseTariffInfoPayload = {
        transport,
        firstLoadingTransport,
        tariffInfo,
        setTariffDataWithNewInfo,
        overwrite,
        routeDetailsInfo,
        updateBaseCurrency,
        setIsFormLoading
      }
      isTransportRent ?
        updateTariffInfoRent({
          ...baseTariffInfoPayload,
          rentPlanData,
          allExtraCosts: extraCosts,
        }) :
        updateTariffInfo({
          ...baseTariffInfoPayload,
          voucher,
          intermPOIList,
          businessProfileId,
        })
    }

    debouncedUpdateRouteCall({
      transport,
      intermPOIList,
      populatedUpdateTariffCall: updateTariffCall,
      setTransport,
    })
  }

  const linkStyle: CSSProperties = useMemo(() => {
    let baseLinkStyle = { background: 'none' }
    let optionalDisabledStyle = isLoading ? disabledStyle : {}
    return { ...optionalDisabledStyle, ...baseLinkStyle }
  }, [isLoading])

  useEffect(() => {
    if (!isTransportEmpty) {
      setFirstLoadingTransport(false)
      // TO BE REMOVED: ONLY BECAUSE IT WAS REQUESTED BY THE CLIENT AS A TEMPORARY FIX
      let taxCurrency = (isTransportRent) ? rentCurrency : null
      if (transport?.id && !selectedVehicleClassId) {
        taxCurrency = null
      }
      // ^^^^
      const futureTariffInfo = extractTariffInfo({ transport, taxCurrency: taxCurrency })
      setTariffInfo({ ...futureTariffInfo })
    }
    return () => {
      debouncedUpdateTariffInfo.cancel()
      debouncedUpdateRouteCall.cancel()
    }
  }, [isTransportEmpty])

  // create 5 empty custom field objects for the company
  const [customFields, setCustomFields] = useState<{ [key: string]: customFieldObj }>({
    companyCustomField1Value: {},
    companyCustomField2Value: {},
    companyCustomField3Value: {},
    companyCustomField4Value: {},
    companyCustomField5Value: {},
  })
  const [clientSpecs, setClientSpecs] = useState<string>('')

  const updateFieldEvent = (e: any, field: any) => {
    // @ts-ignore
    setTransport((oldTransport: TransportType) => {
      const editedTransport = { ...oldTransport }

      if ((e.target || {}).type === 'checkbox') {
        editedTransport[field] = e.target.checked
      } else {
        if (Array.isArray(field)) {
          editedTransport[field[0]] = {}
          editedTransport[field[0]][field[1]] = e.target.value === '' ? null : e.target.value
        } else {
          editedTransport[field] = e.target.value === '' ? null : e.target.value
        }
      }

      return editedTransport
    })
  }

  const updateTransportField = (value: any, fieldName: string) =>
    updateField({ value: value, fieldName: fieldName, setObject: setTransport })

  const updateFields = (fieldValuePairs: any) => {
    // @ts-ignore
    setTransport((oldTransport: TransportType) => {
      return { ...oldTransport, ...fieldValuePairs }
    })
  }

  const updateCompanyObject = async ({
    company,
    supplier = null,
    futureTransport = null,
    byField = true,
    clearCustomFields = false,
  }: {
    company: any
    supplier?: any
    futureTransport?: any
    byField?: boolean
    clearCustomFields?: boolean
  }) => {
    if (company == null) {
      return
    }
    // get the information from the company
    let result = await getCompanyInfo({ id: company.id, businessProfileId: businessProfileId })
    const futureCompanyInfo = result?.data
    if (!futureCompanyInfo) {
      return
    }

    let futureRentCurrency = null;
    if (isTransportRent) {
      // reset the selected state
      resetSelectedRentState()
      setVehicleClasses([])
      setExtraCosts([])
      setInsurances([])

      // complete the new plan
      const futurePlan = await getRentPlanByClientId(company.id)
      setSelectedPlanId(futurePlan?.id || 0)
      // if the plan doesn't exist, or it doesn't have a currency, we should let the original currency
      if (Boolean(futurePlan?.currency)) {
        futureRentCurrency = futurePlan.currency
        setRentCurrency(futureRentCurrency)
        futureCompanyInfo.currency = futureRentCurrency
      }
    }

    if (!byField) {
      setInitialCompanyId(company.id)
    } else {
      setTriggerByFieldCompanyChange(true) // trigger the by field company trigger
      setTariffInfo((prevTariffInfo: TariffInfoType) => {
        const taxBreakdown: BreakdownObjectType = prevTariffInfo?.taxBreakdown || {}
        const commissionBreakdown: BreakdownObjectType = prevTariffInfo?.commissionBreakdown || {}

        const taxBaseCurrency = extractBaseCurrency({
          transport,
          tariffInfo: prevTariffInfo,
          companyType: TypeOfCompany.Client,
        })

        const commissionBaseCurrency = extractBaseCurrency({
          transport,
          tariffInfo: prevTariffInfo,
          companyType: TypeOfCompany.Supplier,
        })
        let taxBreakdownManual = {}
        Object.entries(taxBreakdown).forEach(([key, info]) => {
          if (info.manuallyEdited) {
            const futureInfo = { ...info }
            futureInfo.value = getPriceFullyConverted({ price: futureInfo.value, baseCurrency: taxBaseCurrency, chosenCurrency: futureRentCurrency || company?.currency, exchangeRate: tariffExchangeRate })
            taxBreakdownManual[key] = futureInfo
          }
        })

        let commissionBreakdownManual = {}
        Object.entries(commissionBreakdown).forEach(([key, info]) => {
          if (info.manuallyEdited) {
            const futureInfo = { ...info }
            futureInfo.value = getPriceFullyConverted({ price: futureInfo.value, baseCurrency: commissionBaseCurrency, chosenCurrency: supplier?.currency || futureRentCurrency || company?.currency, exchangeRate: commissionExchangeRate })
            commissionBreakdownManual[key] = futureInfo
          }
        })

        const futureTaxBreakdown = { ...taxBreakdown, ...taxBreakdownManual }
        const futureCommissionBreakdown = { ...commissionBreakdown, ...commissionBreakdownManual }

        const futureTariffInfo = { ...prevTariffInfo, taxBreakdown: futureTaxBreakdown, commissionBreakdown: futureCommissionBreakdown }
        return futureTariffInfo
      })

      if (Boolean(initialCompanyId) && company.id !== initialCompanyId) {
        toast.warning(
          'The company has changed! Future breakdown edits will not be registered! First save the transport request!',
          { autoClose: 8000 }
        )
      }
    }
    if (!futureTransport) {
      // update the transport object with the information retrieved from the company
      setTransport((oldTransport: TransportType) =>
        getEditedTransportWithCompanyInfo({
          oldTransport,
          company,
          supplier,
          companyInfo: futureCompanyInfo,
          byField,
          clearCustomFields,
          changeCurrency: getPricingInfoWithChangedCurrency,
        })
      )
    } else {
      setTransport(
        getEditedTransportWithCompanyInfo({
          oldTransport: futureTransport,
          company,
          supplier,
          companyInfo: futureCompanyInfo,
          byField,
          clearCustomFields,
          changeCurrency: getPricingInfoWithChangedCurrency,
        })
      )
    }
    // update the custom fields in regards to the new company
    setCustomFields({
      ...customFields,
      companyCustomField1Value: {
        label: futureCompanyInfo.customField1,
        mandatory: futureCompanyInfo.customField1Mandatory,
        preFilled: futureCompanyInfo.customField1PreFilled,
      },
      companyCustomField2Value: {
        label: futureCompanyInfo.customField2,
        mandatory: futureCompanyInfo.customField2Mandatory,
        preFilled: futureCompanyInfo.customField2PreFilled,
      },
      companyCustomField3Value: {
        label: futureCompanyInfo.customField3,
        mandatory: futureCompanyInfo.customField3Mandatory,
        preFilled: futureCompanyInfo.customField3PreFilled,
      },
      companyCustomField4Value: {
        label: futureCompanyInfo.customField4,
        mandatory: futureCompanyInfo.customField4Mandatory,
        preFilled: futureCompanyInfo.customField4PreFilled,
      },
      companyCustomField5Value: {
        label: futureCompanyInfo.customField5,
        mandatory: futureCompanyInfo.customField5Mandatory,
        preFilled: futureCompanyInfo.customField5PreFilled,
      },
    })
    // set a client's specification messsage to be displayed
    setClientSpecs(futureCompanyInfo.clientSpecs)
    // get the points of interests from the company's information
    setPois(futureCompanyInfo.pois)
  }

  // handle the route details change event
  const handleRouteChange = () => {
    // if it would be the first time loading the transport object, then we don't need to update the route info
    // we either have no info, because it is a new transport, or we have a populated transport with all the data already set
    if (transport?.originCoords && transport?.destinationCoords && !firstLoadingTransport) {
      populatedTransportRouteCall()
    } else {
      populatedUpdateTariffCall({ overwrite: true })
    }
  }

  useEffect(() => {
    if (!isTransportRent) {
      handleRouteChange()
    }
  }, [
    transport?.originCoords,
    transport?.destinationCoords,
    intermPOIList,
    transport?.effectiveDate,
    transport?.type,
  ])

  useEffect(() => {
    populatedUpdateTariffCall({ overwrite: false })
  }, [
    dislocationType,
    selectedExtraCosts,
    selectedInsuranceId,
    insuranceType,
    selectedVehicleClassId,
    transport?.originCoords,
    transport?.destinationCoords,
    isTransportRent,
    discount,
    transport?.effectiveDate,
    transport?.completeEstimateDate,
  ])

  const updateDislocationType = async () => {
    const futureDislocationType = await getDislocationType({
      originCoords: transport?.originCoords,
      destinationCoords: transport?.destinationCoords,
      businessProfileId
    })
    setDislocationType(futureDislocationType)
  }

  useEffect(() => {
    if (isTransportRent && transport?.originCoords && transport?.destinationCoords && !firstLoadingTransport) {
      updateDislocationType()
    }

    if (isTransportRent && !selectedPlanId) {
      // if we set transport type rent after we already completed the transport form
      getAndUpdateRentPlan(transport?.company?.id)
    }
  }, [isTransportRent, transport?.originCoords, transport?.destinationCoords])

  const getAndUpdateRentPlan = async (companyId: number) => {
    if (!companyId) {
      return;
    }
    const futurePlan = await getRentPlanByClientId(companyId)
    setSelectedPlanId(futurePlan?.id || 0)
    // if the plan doesn't exist, or it doesn't have a currency, we should let the original currency
    if (!futurePlan?.currency) {
      return;
    }
    const futureRentCurrency = futurePlan.currency
    setRentCurrency(futureRentCurrency)
    if (!isTransportEmpty) {
      onChangeCurrency(futureRentCurrency)
    }
  }

  // if we triggererd the by field flag, we need to recompute the tariffInfo
  useEffect(() => {
    populatedUpdateTariffCall({ overwrite: true, updateBaseCurrency: triggerByFieldCompanyChange })
    if (triggerByFieldCompanyChange) {
      setTriggerByFieldCompanyChange(false)
    }

    return () => {
      setTriggerByFieldCompanyChange(false)
    }
  }, [transport?.company?.id])

  useEffect(() => {
    if (triggerCheckPaymentType) {
      changePaymentTypeEffect(transport?.paymentType)
      setTriggerCheckPaymentType(false)
    }

    return () => {
      setTriggerCheckPaymentType(false)
    }
  }, [triggerCheckPaymentType])

  useEffect(() => {
    populatedUpdateTariffCall({ overwrite: true })
  }, [transport?.supplier?.id, transport?.tariffServiceId, transport?.tariffTypeId])

  const filteredDrivers =
    !companyInfo.drivers || companyInfo.drivers.length === 0 ? drivers || [] : companyInfo.drivers

  // const getObjectFromProperty = (value: any, label: string, list: any) => {return list.find((item : any) => item[label] == value) || ''}

  const getPricingInfoWithChangedCurrency = (transport: TransportType, newCurrency: string) => {
    const fromCurrency = transport?.currency
    const toCurrency = newCurrency
    let futureTariff = transport?.tariff || 0
    let futureCommission = transport?.commission || 0
    if (fromCurrency === 'EUR' && toCurrency === 'RON') {
      futureTariff *= tariffExchangeRate
    } else if (fromCurrency === 'RON' && toCurrency === 'EUR') {
      futureTariff /= tariffExchangeRate
    }
    setFormattedTariff(getPriceFormatted(futureTariff))
    setFormattedCommission(getPriceFormatted(futureCommission))
    return { futureTariff, futureCommission }
  }

  const onChangeCurrency = (newCurrency: string) => {
    const { futureTariff, futureCommission } = getPricingInfoWithChangedCurrency(
      transport,
      newCurrency
    )
    setTransport({
      ...transport,
      currency: newCurrency,
      tariff: futureTariff,
      commission: futureCommission,
    })
  }

  function fieldToTransportProperty(field: any, transport: TransportType) {
    let fieldName, fieldType
    if (field) {
      const { name, type } = field
      fieldName = name
      fieldType = type
    }
    if (!id || Object.keys(transport).length !== 0) {
      // if(isFieldInTransport(fieldName)) {
      const paymentTypes = (dictionaries.PAYMENT_TYPES || []).filter((payment: any) => {
        if (!companyInfo || !companyInfo.paymentTypes || companyInfo.paymentTypes.length === 0) {
          return true
        }
        return companyInfo.paymentTypes.includes(payment.name)
      })

      const fieldsToTransportPropertiesMapping = {
        clientSpecs: clientSpecs,
        code: {
          simpleValue: transport?.transportCodeCode || '',
          getOptionValue: (e: any) => e?.code || '',
          getOptionLabel: (e: any) => e?.code || '',
          loadOptions: (e: any) =>
            getTransportCodes({ code: e, businessProfileId: businessProfileId }),
        },
        idCompanyClient: {
          value: transport?.company || {},
          options: companies || [],
          getOptionValue: (e: any) => e,
          getOptionLabel: (e: any) => e?.name || '',
          onChange: (e: any) => { setIsFormLoading(true); updateCompanyObject({ company: e, clearCustomFields: true }) },
        },
        supplier: {
          value: transport?.supplier || {},
          options: suppliers || [],
          getOptionValue: (e: any) => e,
          getOptionLabel: (e: any) => e?.name || '',
          onChange: (e: any) => { setIsFormLoading(true); updateTransportField(e, 'supplier') },
        },
        revenueCenter: {
          simpleValue: transport?.revenueCenter || '',
          options: dictionaries?.REVENUE_CENTERS || [],
          getOptionValue: (e: any) => e?.name,
          getOptionLabel: (e: any) => e?.title || '',
          onChange: (e: any) => updateTransportField(e, 'revenueCenter'),
        },
        requestorName: transport.requestorName,
        requestorPhone: transport.requestorPhone,
        requestorEmail: transport.requestorEmail,
        companyCustomField1Value: transport.companyCustomField1Value,
        companyCustomField2Value: transport.companyCustomField2Value,
        companyCustomField3Value: transport.companyCustomField3Value,
        companyCustomField4Value: transport.companyCustomField4Value,
        companyCustomField5Value: transport.companyCustomField5Value,
        promoCode: transport.promoCode,
        effectiveDateString: transport.effectiveDate,
        idPoiOrigin: {
          value: transport.poiOrigin ?? '',
          options: (pois || []).filter((poi: any) => poi.additionalInfo),
          getOptionValue: (e: any) => e,
          getOptionLabel: (e: any) => e?.additionalInfo || '',
        },
        originCoords: transport.originCoords,
        origin: transport.origin,
        originAdditionalInfo: transport.originAdditionalInfo,
        originGeoFence: transport.originGeoFence,
        idPoiDestination: {
          value: transport.poiDestination ?? '',
          options: (pois || []).filter((poi: any) => poi.additionalInfo),
          getOptionValue: (e: any) => e,
          getOptionLabel: (e: any) => e?.additionalInfo || '',
        },
        destinationCoords: transport.destinationCoords,
        destination: transport.destination,
        destinationAdditionalInfo: transport.destinationAdditionalInfo,
        destinationGeoFence: transport.destinationGeoFence,
        idTariffService: {
          simpleValue: transport.tariffServiceId,
          options: tariffServices || [],
          getOptionValue: (e: any) => e?.id || '',
          getOptionLabel: (e: any) => e?.name || '',
          onChange: (tariffServiceId: any) => {
            setIsFormLoading(true)
            let tariffServiceInfo = tariffServiceDict[tariffServiceId]
            const futureTransportInfo = getTransportInfoByServiceChange({ isEditMode, tariffServiceInfo, tariffServiceId })
            setTransport({ ...transport, ...futureTransportInfo })
          },
        },
        paymentTypeString: {
          simpleValue: transport.paymentType,
          options: paymentTypes,
          getOptionValue: (e: any) => e.name || '',
          getOptionLabel: (e: any) => e.title || '',
          onChange: changePaymentTypeEffect
        },
        tariffString: { value: formattedTariff, setValue: setFormattedTariff },
        blockTariff: transport.blockTariff,
        currencyString: {
          simpleValue: transport.currency,
          options: dictionaries.CURRENCIES || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.title || '',
          onChange: (newCurrency: string) => onChangeCurrency(newCurrency),
        },
        paymentStatusString: {
          simpleValue: transport.paymentStatus || defaultPaymentStatus,
          options: dictionaries.PAYMENT_STATUSES || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.title || '',
          onChange: (e: any) => updateTransportField(e, 'paymentStatus'),
        },
        idUser: transport.user && transport.user.username,
        requestDateString: transport.requestDate,
        initialComment: transport?.comment || '',
        billedCustomer: transport.billedCustomer,
        customerAddress: transport.customerAddress,
        paymentNote: transport.paymentNote,
        invoiceNumber: transport.invoiceNumber,
        taxBreakdownTransferType:
          getTransportTypeString({
            transportTypeName: transport?.taxBreakdownTransferType,
            transportTypes: dictionaries.PRICING_TRANSFER_TYPES,
          }) || 'Unknown',
        taxBreakdownOriginAgency: transport?.taxBreakdownOriginAgency || '',
        commission: { value: formattedCommission, setValue: setFormattedCommission },
        blockCommission: transport.blockCommission,
        freeCancellation: transport.freeCancellation,
        passengerName: {
          value: passengers.mainPassenger,
          handleOnChange: (event: any) => {
            if (event.type === 'blur') {
              return // onBlur will handle the value change
            }
            const name = event.target.value
            setPassengers({ mainPassenger: name, otherPassengers: passengers.otherPassengers })
          },
          onBlur: (
            name: string // we want the change to happen onBlur, not onChange
          ) =>
            setPassengerNames({ mainPassenger: name, otherPassengers: passengers.otherPassengers }),
        },
        language: {
          simpleValue: transport.language,
          options: dictionaries.LANGUAGES || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.title || '',
          onChange: (e: any) => updateTransportField(e, 'language'),
        },
        passengerPhone: transport.passengerPhone,
        passengerEmail: transport.passengerEmail,
        passengerReference: transport.passengerReference,
        passengers: transport.passengers,
        otherPassengers: {
          value: passengers.otherPassengers,
          onChange: (names: string[]) =>
            setPassengerNames({
              mainPassenger: passengers.mainPassenger,
              otherPassengers: names,
            }),
        },
        vip: Boolean(transport.vip),
        assignmentBlocked: transport.assignmentBlocked,
        driverNotification: {
          value: transport?.driverNotification || '',
          maxLength: 300,
          additionalInfo: `characters ${(transport?.driverNotification || '').length}/300`,
        },
        greeterSignLogo: transport.greeterSignLogo,
        greeterSignName: transport.greeterSignName,
        greeterSignOther: transport.greeterSignOther,
        greeterSignActivations: transport.greeterSignActivations,
        hasUnsolvedIssues: transport.hasUnsolvedIssues,
        requestStatusString: {
          simpleValue: transport.requestStatus,
          options: dictionaries.REQUEST_STATUSES || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.title || '',
          onChange: (futureStatus: any) => {
            const statusFromStarted = transport.requestStatus === "STARTED"
            const statusToCompleted = futureStatus === "COMPLETED" || futureStatus === "COMPLETED_POS"
            if (statusFromStarted && statusToCompleted) {
              toast.warning(
                "WARNING! Invalid behaviour! The pricing might be incorrect! If you want to set a request to COMPLETED or COMPLETED POS please use the menu button option from Transport Assignments!",
                { autoClose: 8000 }
              )
            }
            updateTransportField(futureStatus, 'requestStatus')
          },
        },
        idTariffType: {
          simpleValue: transport.tariffTypeId ?? '',
          options: tariffTypes || [],
          getOptionValue: (e: any) => e?.id || '',
          getOptionLabel: (e: any) => e?.name || '',
          onChange: (e: any) => { setIsFormLoading(true); updateTransportField(e, 'tariffTypeId') },
        },
        duration: {
          value: transport.duration || 0,
          onChange: (newDuration: any) => {
            populatedUpdateTariffCall({
              overwrite: false,
              routeDetailsInfo: { duration: newDuration },
            })
            updateTransportField(newDuration, 'duration')

            const effectiveDateMoment = moment(transport?.effectiveDate) ?? moment()
            const newCompleteEstimatedDate = effectiveDateMoment.add(newDuration, 'minutes').format('YYYY-MM-DD HH:mm:ss.SSS')
            updateTransportField(newCompleteEstimatedDate, 'completeEstimateDate')
          },
        },
        systemEstimatedDuration: transport.systemEstimatedDuration ?? undefined,
        systemEstimatedDurationBreakdown: transport.systemEstimatedDurationBreakdown,
        completeEstimateDateString: transport?.completeEstimateDate,
        distance: {
          value: transport.distance || '0',
          onChange: (newDistance: any) => {
            populatedUpdateTariffCall({
              overwrite: false,
              routeDetailsInfo: { distance: newDistance },
            })
            updateTransportField(newDistance, 'distance')
          },
          onBlur: (value: number | string) => {
            return Math.ceil(parseFloat('' + (value || '0'))).toString()
          },
        },
        stops: transport.stops,
        stopsCost: transport.stopsCost ?? undefined,
        wait: transport.wait,
        waitCost: transport.waitCost ?? undefined,
        estimatedWait: {
          value: transport.estimatedWait || '0',
          onChange: (newEstimatedWait: any) => {
            populatedUpdateTariffCall({
              overwrite: false,
              routeDetailsInfo: { estimatedWait: newEstimatedWait },
            })
            updateTransportField(newEstimatedWait, 'estimatedWait')
          },
        },
        idVehicleAssigned: {
          value: transport.assignedVehicle,
          options: getSortedList({ listToSort: vehicles || [], args: ['plateNumber', 'unitNumber'] }),
          getOptionValue: (e: any) => e || '',
          getOptionLabel: (e: any) => e?.businessName || '',
          onChange: (e: any) => updateTransportField(e, 'assignedVehicle'),
          sorted: true,
        },
        idDriverAssigned: {
          value: transport.assignedDriver,
          options: filteredDrivers || [],
          getOptionValue: (driver: any) => driver || '',
          getOptionLabel: (driver: any) => driver?.fullName || '',
          onChange: (driver: any) => {
            const supplierId = driver?.supplierId
            const supplier = Boolean(supplierId)
              ? suppliers.find((supplier: any) => supplier.id === supplierId)
              : null
            if (supplier) {
              setTransport((transport: TransportType) => ({
                ...transport,
                assignedDriver: driver,
                supplier: supplier,
              }))
            } else {
              updateTransportField(driver, 'assignedDriver')
            }
          },
        },
        transferTypeString: {
          simpleValue: transport.transferType,
          options: dictionaries.TRANSFER_TYPES || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.title || '',
          onChange: (e: any) => updateTransportField(e, 'transferType'),
        },
        photos: transport.photos || [],
        pvs: transport.pvs || [],
        comment: transport.comment || '',
        requestRemarkString: {
          simpleValue: transport.requestRemark,
          options: dictionaries.REQUEST_REMARKS || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.title || '',
          onChange: (e: any) => updateTransportField(e, 'requestRemark'),
        },
        type: {
          simpleValue: transport.type || defaultTransportType,
          options: dictionaries.TRANSPORT_REQUEST_TYPES || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.title || '',
          onChange: (type: string) => {
            setIsFormLoading(true)
            const futureTariffInfoByTypeChange = getTransportInfoByTypeChange({ isEditMode, oldType: transport?.type, newType: type, rentACarService, standardService })
            setTransport({ ...transport, ...futureTariffInfoByTypeChange })
          },
        },
        dispatchComment: transport?.dispatchComment || '',
        emailInputTime: `${transport.emailInputTime} Email Input`,
        analyzeTime: `${transport.analyzeTime} Analyze`,
        toBeAcceptedTime: `${transport.toBeAcceptedTime} To be accepted`,
        delayAnnounced: transport.delayAnnounced,
        delayAnnouncedTimestamp: `Delay announced timestamp: ${transport.delayAnnounced ? transport.delayAnnouncedTimestamp : ''
          }`,
        announcedDelay: transport.announcedDelay,
        realDelay: transport.realDelay,
        assignResponsible: [
          transport.responsibleForAssignUser?.username,
          '02h: 16min', // from where does the time value come?
        ],
        supervisorResponsible: transport.supervisor?.profile?.fullName ?? '',
        checkedBy: transport.lastCheckedBy,
        assistanceVehiclePlateNumber: {
          simpleValue: transport.assistanceVehiclePlateNumber ?? '',
          getOptionValue: (e: any) => e?.plateNumber || '',
          getOptionLabel: (e: any) => e?.plateNumber || '',
          loadOptions: (e: any) =>
            getAllVehicles({ plateNumber: e, businessProfileId: businessProfileId }),
          onInputChange: (value: string) => {
            updateTransportField(value, 'assistanceVehiclePlateNumber')
          },
        },
        assistanceVehicleVin: transport.assistanceVehicleVin,
        assistanceVehicleBrand: {
          simpleValue: transport.assistanceVehicleBrand || '',
          options: vehicleBrands || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.name || '',
          onChange: (e: any) => {
            updateTransportField(e, 'assistanceVehicleBrand')
            // reset the model of the car, because we changed the brand
            updateTransportField(null, 'assistanceVehicleModel')
          },
        },
        assistanceVehicleModel: {
          simpleValue: transport.assistanceVehicleModel,
          options:
            (vehicleBrands || []).find(
              (vehicleBrand: any) => vehicleBrand.name === transport.assistanceVehicleBrand
            )?.models || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.name || '',
          onChange: (e: any) => updateTransportField(e, 'assistanceVehicleModel'),
        },
        assistanceVehicleType: {
          simpleValue: transport.assistanceVehicleType ?? '',
          options: vehicleTypes || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.name || '',
          onChange: (e: any) => updateTransportField(e, 'assistanceVehicleType'),
        },
        assistanceVehicleManufactureYear: transport.assistanceVehicleManufactureYear ?? undefined,
        assistanceVehicleDepartment: {
          simpleValue: transport.assistanceVehicleDepartment,
          options: departments || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.name || '',
          onChange: (e: any) => updateTransportField(e, 'assistanceVehicleDepartment'),
        },
        assistanceVehicleLocation: transport.assistanceVehicleLocation,
        assistanceVehicleReplacementClass: transport.assistanceVehicleReplacementClass,
        assistanceVehicleReplacementDays: transport.assistanceVehicleReplacementDays ?? undefined,
        assistanceVehicleRoadAssistDescription: transport.assistanceVehicleRoadAssistDescription,
        assistanceVehicleDtdDescription: transport.assistanceVehicleDtdDescription,
        replacementVehiclePlateNumber: {
          simpleValue: transport.replacementVehiclePlateNumber ?? '',
          getOptionValue: (e: any) => e?.plateNumber || '',
          getOptionLabel: (e: any) => e?.plateNumber || '',
          loadOptions: (e: any) =>
            getAllVehicles({ plateNumber: e, businessProfileId: businessProfileId }),
          onInputChange: (value: string) => {
            updateTransportField(value, 'replacementVehiclePlateNumber')
          },
        },
        replacementVehicleBrand: {
          simpleValue: transport.replacementVehicleBrand,
          options: vehicleBrands || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.name || '',
          onChange: (e: any) => {
            updateTransportField(e, 'replacementVehicleBrand')
            updateTransportField(null, 'replacementVehicleModel')
          },
        },
        replacementVehicleModel: {
          simpleValue: transport.replacementVehicleModel,
          options:
            (vehicleBrands || []).find(
              (vehicleBrand: any) => vehicleBrand.name === transport.replacementVehicleBrand
            )?.models || [],
          getOptionValue: (e: any) => e?.name || '',
          getOptionLabel: (e: any) => e?.name || '',
          onChange: (e: any) => updateTransportField(e, 'replacementVehicleModel'),
        },
        replacementVehicleLocation: transport.replacementVehicleLocation,
        replacementVehicleReturnEstimateDateString:
          getReplacementEstimateDateFromTransport(transport),
        emissions: transport.emissions,
        applicantId: {
          value: '',
          options: [],
          onChange: (e: any) => updateFieldEvent(e, 'applicantId'),
        }, // does transport.applications contain the options, if yes then from whre to fetch the selected value, if not than is the selected value  an array ?
        denierId: { value: '', options: [], onChange: (e: any) => updateFieldEvent(e, 'denierId') }, // from where to get options and selected value
        delayerDriver: transport.delayerDriver,
        alertHistories: [
          `${transport.alertHistories?.user} ${transport.alertHistories?.timeToEffectiveDate}`,
          transport.alertHistories?.comment,
        ],
        startOdometer: transport.startOdometer || 0,
        endOdometer: transport.endOdometer || 0,
        driverKm: getDriverKm({
          startOdometer: transport.startOdometer,
          endOdometer: transport.endOdometer,
        }),
        gpsKm: transport.gpsKm || 0,
        startTime: transport.startTime || '',
        endTime: transport.endTime || '',
        googleReadyDistance: transport.googleReadyDistance || 0,
        gpsReadyDistance: transport.gpsReadyDistance || 0,
        driverTransportStops: transport.driverTransportStops || [],
        systemTransportStops: transport.systemTransportStops || [],
        gpsMonitored: transport.gpsMonitored || false,
        // rent fields
        vehicleClass: {
          simpleValue: selectedVehicleClassId || '',
          options: getSortedList({ listToSort: vehicleClasses || [], args: ['name'] }),
          onChange: setSelectedVehicleClassId,
          getOptionLabel: (option: any) => option.name,
          getOptionValue: (option: any) => option.id
        },
        extraOptions: {
          simpleValue: selectedExtraCosts || [],
          options: getSortedList({
            listToSort:
              (extraCosts || []).filter((extra_costs) => extra_costs.is_applicable !== 'per week day'),
            args: ['name']
          }),
          onChange: setSelectedExtraCosts,
          getOptionLabel: (option: any) => option.name,
          getOptionValue: (option: any) => option.id
        },
        dislocationType: dislocationType || 'Unknown',
        insurance: {
          simpleValue: selectedInsuranceId || '',
          options: getSortedList({ listToSort: filteredInsurances || [], args: ['name'] }),
          onChange: setSelectedInsuranceId,
          getOptionLabel: (option: any) => option.name,
          getOptionValue: (option: any) => parseInt('' + option.id)
        },
        insuranceType: {
          simpleValue: insuranceType || [],
          options: insuranceTypeList || [],
          onChange: setInsuranceType,
          getOptionLabel: (option: any) => option.label,
          getOptionValue: (option: any) => option.value,
        },
        discount: {
          value: discount || 0,
          effectOverValueOnChange: (val: number | string) => {
            if (val != 0 && !Boolean(val)) return '0'
            let parsedValue = parseFloat(val + '')
            const maxVal = 100 - epsilon
            return parsedValue < epsilon ? '0' : parsedValue > maxVal ? '100' : val + ''
          },
          onChange: setDiscount
        },
        rentalPeriod: numOfRentalDays
      }

      return (fieldsToTransportPropertiesMapping as any)[fieldName]
    }
    switch (fieldType) {
      case 'select':
        return { value: '', options: [], onChange: () => { } }
    }
  }

  useEffect(() => {
    ; (async () => {
      let futureTransport = null
      if (id) {
        const result = await getTransports({ id })
        if (!Boolean(result?.data) || !Array.isArray(result?.data) || !Boolean(result?.data[0])) {
          return toast.error(`There is no transport with id ${id}!`)
        } else {
          futureTransport = result?.data[0]
          let futureRentCurrency = null
          if (futureTransport.type === 'RENT') {
            const transportRentData = await getTransportRentInfo(futureTransport.id)
            if (Boolean(transportRentData)) {
              setSelectedExtraCosts(transportRentData.extra_costs || [])
              setSelectedInsuranceId(transportRentData.insurance_id || 0)
              setInsuranceType(transportRentData.liability_type || [])
              setSelectedVehicleClassId(transportRentData.vehicle_class_id || 0)
              futureRentCurrency = transportRentData.currency
              setRentCurrency(futureRentCurrency)
              setSelectedPlanId(transportRentData.plan_id || 0)
              setDiscount('' + ((transportRentData.discount || 0) * 100))
              setDislocationType(transportRentData.dislocation_type || null)
              // add the rent exchange rate to the tariff details
              const rentExchangeRate = transportRentData.exchange_rate
              if (rentExchangeRate) {
                futureTransport = getUpdatedTransportWithRentExchangeRate({ transport: futureTransport, rentExchangeRate })
              }
            } else {
              getAndUpdateRentPlan(futureTransport?.company?.id)
            }
          }

          if (!Boolean(futureTransport?.tariff)) {
            let futureTariffInfo = extractTariffInfo({ transport: futureTransport, taxCurrency: isTransportRent ? futureRentCurrency : null })
            let tariffObject = getPriceByTariffInfoPopulated({
              transport: futureTransport,
              tariffInfo: futureTariffInfo,
            })
            futureTransport.tariff = tariffObject.price
            setFormattedTariff(tariffObject.formattedPrice)

            let commissionObject = getPriceByTariffInfoPopulated({
              transport: futureTransport,
              tariffInfo: futureTariffInfo,
              companyType: TypeOfCompany.Supplier,
            })
            futureTransport.commission = commissionObject.price
            setFormattedCommission(commissionObject.formattedPrice)
          } else {
            setInitialTariff(futureTransport?.tariff)
            setFormattedTariff(getPriceFormatted(futureTransport?.tariff))
            setInitialCommission(futureTransport?.commission)
            setFormattedCommission(getPriceFormatted(futureTransport?.commission))
          }

        }
      } else {
        futureTransport = {
          intermediaryStops: 0,
          currency: 'RON',
          readyNotificationSent: false,
          businessProfile: {
            id: businessProfileId,
          },
          requestDate: moment().format('YYYY-MM-DDTHH:mm:00.000'),
          language: 'RO',
          estimatedWait: 0,
          tariff: 0,
          commission: 0,
          requestStatus: defaultRequestStatus,
          paymentStatus: defaultPaymentStatus,
          type: defaultTransportType,
          gpsMonitored: true,
          taxBreakdown: {},
          commissionBreakdown: {},
        }

        if (action != null && Boolean(transportIdForAction)) {
          const result = await getTransports({ id: transportIdForAction })
          if (!Boolean(result?.data) || !Array.isArray(result?.data) || !Boolean(result?.data[0])) {
            toast.error(
              `There is no transport with id ${transportIdForAction}! Aborting action! Switching to default behaviour!`
            )
          } else {
            const transportForAction = result?.data[0]
            if (action === 'repeat') {
              futureTransport = getTransportForRepeat(transportForAction)
              setInitialTariff(transportForAction?.tariff)
              setInitialCommission(transportForAction?.commission)
              toast.info(`Repeat transport request with id ${transportIdForAction}`)
            } else if (action === 'return') {
              futureTransport = getTransportForReturn(transportForAction)
              toast.info(`Return transport request with id ${transportIdForAction}`)
            } else {
              toast.error(
                `An action was identified, but there is no known implementation for: action('${action}')`
              )
            }
          }
        } else if (action != null || transportIdForAction != null) {
          toast.error(
            `There was an attempt to make an action, but it failed: action('${action}'), transportId('${transportIdForAction || 'invalid'
            }') `
          )
        }
      }

      const futurePOIList = getIntermPointsList(futureTransport)
      setIntermPOIList(futurePOIList)
      if (!futureTransport?.company?.id) {
        setTransport(futureTransport)
      } else {
        const companyResponse = await getCompanyRequest({ id: futureTransport.company.id, businessProfileId, simple: true })
        let company = companyResponse?.data || futureTransport?.company
        let supplier = futureTransport.supplier
        if (supplier?.id) {
          const supplierResponse = await getCompanyRequest({ id: supplier.id, businessProfileId, simple: true })
          supplier = supplierResponse?.data || supplier
        }
        // updates all fields related to the company
        updateCompanyObject({ company, supplier, futureTransport, byField: false }) // the modifications were not done via field, but at loading
      }
      setExtraTitle(futureTransport.voucher)
    })()
  }, [])

  useEffect(() => {
    if (!firstLoadingTransport && Boolean(action) && (action === 'repeat' || action === 'return')) {
      populatedUpdateTariffCall({ overwrite: true })
    }
  }, [firstLoadingTransport])

  const generateLabel = (fieldLabel: string, fieldRequired: boolean) => {
    return (
      <>
        {fieldLabel}:
        {fieldRequired && (
          <span className='ps-2' style={{ color: 'red' }}>
            *
          </span>
        )}
      </>
    )
  }

  const generateTransportField = (field: any, index?: number, length?: number): any => {
    const isFieldDisabled = Boolean(field['disabled']) || isFormLoading
    const fieldLabel = generateLabel(field?.label, field?.required)
    const fieldProperty = fieldToTransportProperty(field, transport)
    let fieldName = (convertFieldName as any)[field?.name] || field?.name
    switch (field?.type) {
      case 'optionsSelect':
        return (
          <OptionsSelectWrapper options={extraCosts.filter((extra_costs) => extra_costs.is_applicable !== 'per week day')} selectedOptions={selectedExtraCosts} setSelectedOptions={setSelectedExtraCosts} currency={rentCurrency || 'RON'} />
        )
      case 'tariffCurrencyInput':
        const includedVAT = (fieldName === 'commission') ? false : hasIncludedVATByPaymentType(transport?.paymentType)
        const currentValue = '' + (fieldProperty.value || '0')
        const setValue = fieldProperty.setValue
        const shownCurrency = (fieldName === 'commission') ? commissionCurrency : transport?.currency
        return (
          <CurrencyInput
            value={currentValue}
            field={field}
            onChange={(e) => {
              let newValue = '' + (e.target.value || '0')
              if (currentValue != newValue) {
                setTransport({ ...transport, [fieldName]: newValue })
              }
              setValue(newValue)
            }}
            additionalInfo={`${shownCurrency} ${includedVAT ? 'VAT included' : 'Without VAT'}`}
          />
        )
      case 'breakdownInfo':
        const chosenCurrency = (field.name === 'taxBreakdown') ? (transport?.currency || tariffInfo?.currency) : commissionCurrency
        const baseCurrency = (field.name === 'taxBreakdown') ? extractBaseCurrency({
          transport,
          tariffInfo,
          companyType: field?.companyType,
        }) : commissionCurrency
        return (
          <BreakdownCardWrapper
            key={field?.name}
            label={field.label + ':'}
            breakdownIdentifier={field.name}
            canEdit={true}
            tariffInfo={tariffInfo}
            setTariffInfo={(futureTariffInfo: TariffInfoType) => {
              // will be replaced with a named function - current is not ideal
              if (field.name === 'taxBreakdown') {
                let tariffObject = getPriceByTariffInfoPopulated({ transport, tariffInfo: futureTariffInfo })
                setTransport({ ...transport, tariff: tariffObject.price || 0 })
                setFormattedTariff(tariffObject.formattedPrice)
              } else if (field.name === 'commissionBreakdown') {
                let commissionObject = getPriceByTariffInfoPopulated({
                  transport,
                  tariffInfo: futureTariffInfo,
                  companyType: TypeOfCompany.Supplier,
                })
                setTransport({ ...transport, commission: commissionObject.price || 0 })
                setFormattedCommission(commissionObject.formattedPrice)
              }
              setTariffInfo(futureTariffInfo)
            }}
            initialVAT={VAT}
            initialExchangeRate={(field.name === 'taxBreakdown') ? tariffExchangeRate : commissionExchangeRate}
            chosenCurrency={chosenCurrency}
            baseCurrency={baseCurrency}
            paymentType={(field.name === 'taxBreakdown') ? transport?.paymentType : 'CONTRACT'}
            gpsMonitored={transport?.gpsMonitored}
          />
        )
      case 'courseMap':
        const originGPS = transport.originCoords
        const destinationGPS = transport.destinationCoords
        return (
          <OptionalCourseMapWrapper
            showMapInitial={!isEditMode}
            startLocationCoords={originGPS}
            endLocationCoords={destinationGPS}
            waypointsCoordsArray={
              intermPOIList
                ? intermPOIList.map((poi: POI) => poi.gps)
                : []
            }
          />
        )
      case 'customInput':
        return (
          <CustomInputField
            customFields={customFields}
            key={field?.name}
            value={fieldProperty || ''}
            field={field}
            onChangeInput={(ev) => {
              updateFieldEvent(ev, fieldName)
            }}
            onChangeSelect={(value: any) => updateTransportField(value, field?.name)}
          />
        )
      case 'searchSubscriptions':
        return (
          <SearchSubscriptionsField field={field} setSubscriptions={setSubscriptions} vehiclePlateNumber={transport.assistanceVehiclePlateNumber} />
        )
      case 'displaySubscriptions':
        return (
          <SubscriptionsDisplayField field={field} subscriptions={subscriptions} showCheckbox checkedSubscription={checkedSubscription} setCheckedSubscription={setCheckedSubscription} />
        )
      case 'selectWithAutocomplete':
        return (
          <>
            <label
              key={`label${field?.name}`}
              htmlFor={field?.name}
              className={'form-label ' + (field?.labelType === 'danger' ? 'text-danger' : '')}
            >
              {fieldLabel}
            </label>
            <SelectAsync
              name={field?.name}
              key={field?.name}
              isDisabled={isFieldDisabled}
              loadOptions={fieldProperty.loadOptions}
              simpleValue={fieldProperty.simpleValue}
              onChange={(optionsInstance: any) => {
                // change the value of the current field
                let newValue = fieldProperty.getOptionValue(optionsInstance)
                updateTransportField(newValue, fieldName)
                // change the values of the secondary fields
                let autocompleteFields = field?.autocomplete

                Object.entries(autocompleteFields).forEach((field: any) => {
                  let optionValue = optionsInstance[field[1]]
                  let fieldName = field[0]
                  if (fieldName === 'company') {
                    updateCompanyObject({ company: optionValue })
                    return
                  }
                  updateTransportField(optionValue, fieldName)
                })
              }}
              onInputChange={fieldProperty.onInputChange}
              getOptionValue={fieldProperty.getOptionValue}
              getOptionLabel={fieldProperty.getOptionLabel}
              editable={field?.editable}
            />
          </>
        )
      case 'geolocation':
        return (
          <>
            <label key={`label${field?.name}`} htmlFor={field?.name} className='form-label'>
              {fieldLabel}
              <i
                onClick={async () => {
                  // Add text to clipboard

                  navigator.clipboard.writeText(
                    fieldToTransportProperty({ name: field?.coordsKey, type: 'input' }, transport) ||
                    ''
                  )
                  toast.success('Coords copied to clipboard!')
                }}
                className='fas fa-exclamation-circle ms-2 fs-7 clickable'
                data-bs-toggle='tooltip'
                title={
                  fieldToTransportProperty({ name: field?.coordsKey, type: 'input' }, transport) || ''
                }
              ></i>
            </label>

            <GeolocationSelect
              simpleValue={fieldProperty}
              isDisabled={isFieldDisabled}
              onChange={async (params: any) => {
                const { place_id, description } = params

                // Update coords
                if (!field?.coordsKey) {
                  return null
                }

                // Update zone
                if (!field?.zoneKey) {
                  return null
                }

                // Update additional info
                const placeDetails = await getPlaceDetails({ place_id: place_id })

                const coords: { lat?: number; lng?: number } = {
                  lat: placeDetails.geometry.location.lat(),
                  lng: placeDetails.geometry.location.lng(),
                }

                const zoneResponse = await getEasyTrackZone({ lat: coords.lat, lng: coords.lng })
                // Not finished, waiting for Valentin Response.
                let zone: { name?: string } = zoneResponse.data || { name: '' }

                let fieldsValuePairs: any = {}
                fieldsValuePairs[
                  (convertFieldName as any)[field?.additionalInfoKey] || field?.additionalInfoKey
                ] = placeDetails.name
                fieldsValuePairs[(convertFieldName as any)[field?.name] || field?.name] =
                  description
                fieldsValuePairs[
                  (convertFieldName as any)[field?.coordsKey] || field?.coordsKey
                ] = `${coords.lat},${coords.lng}`
                fieldsValuePairs[(convertFieldName as any)[field?.zoneKey] || field?.zoneKey] =
                  zone.name
                updateFields(fieldsValuePairs)
                if (field?.name === 'origin' || field?.name === 'destination') {
                  setDislocationType(null) // if the location changes, we need to recalculate the dislocation type
                }
              }}
              onInputChange={(value: string) => {
                const fieldName = (convertFieldName as any)[field?.name] || field?.name
                updateTransportField(value, fieldName)
              }}
            />

            {field?.additionalInfoKey ? (
              <input
                key={field?.name}
                name={field?.name}
                type='text'
                placeholder='Additional info'
                className='form-control form-control-sm mt-1'
                id={field?.name}
                onChange={(e) => {
                  updateFieldEvent(
                    e,
                    (convertFieldName as any)[field?.additionalInfoKey] || field?.additionalInfoKey
                  )
                }}
                value={
                  fieldToTransportProperty(
                    { name: field?.additionalInfoKey, type: 'input' },
                    transport
                  ) || ''
                }
              />
            ) : null}
          </>
        )
      case 'transportPhotos':
        return (
          <TransportDocumentWrapper
            transportRequest={transport}
            documents={fieldProperty}
            documentTypes={dictionaries.PHOTO_TYPES || []}
            field={field}
            type='photos'
          />
        )
      case 'transportPvs':
        return (
          <TransportDocumentWrapper
            transportRequest={transport}
            documents={fieldProperty}
            documentTypes={dictionaries.PHOTO_TYPES || []}
            field={field}
            type='pvs'
          />
        )
      case 'customFieldForRepeat':
        const effectiveDate = moment(transport.effectiveDate || new Date())
        return (
          <RepeatRequestField
            currentDate={{
              year: effectiveDate.year(),
              month: effectiveDate.month() + 1,
              day: effectiveDate.date(),
              hour: effectiveDate.hour(),
              minute: effectiveDate.minute(),
            }}
            index={index}
            length={length}
            repeatOnDates={repeatOnDates}
            setRepeatOnDates={setRepeatOnDates}
            field={field}
          />
        )
      case 'poiComponent':
        return (
          <PoiFormWrapper
            disabled={isFieldDisabled}
            field={field}
            intermPOIList={intermPOIList}
            setIntermPOIList={(e) => {
              // if e is not array
              if (!Array.isArray(e)) {
                setIntermPOIList(e)
                return
              }
              const totalWaitingTime = e.reduce((acc, curr) => {
                return Number(acc) + Number(curr.estimatedWait || 0)
              }, 0)

              setIntermPOIList(e)
              updateTransportField(totalWaitingTime, 'estimatedWait')
            }}
            pois={pois}
          />
        )
      case 'composite':
        return (
          <div className='composite'>
            {field?.title && (
              <h5 key={field?.name} className='pb-4 mb-7 border-bottom text-primary'>
                {field?.title}:
              </h5>
            )}
            {field?.fields?.map((field: any, index: number, fields: any) =>
              generateTransportField(field, index, fields.length)
            )}
          </div>
        )
      default:
        let futureField = { ...field }
        if (transportFieldsTariff.has(field?.name)) {
          futureField['disabled'] = isFieldDisabled
        }
        return generateField({
          field: futureField,
          fieldName,
          fieldProperty,
          setObject: setTransport,
        })
    }
  }

  if (isTransportEmpty) {
    return <LoadingCard />
  }

  const prepareDataAndSubmit = (recomputeStatus?: RecomputeStatus | null) => {
    // this function is not ideal, I added it only to respect DRY
    const initialTransportData: InitialTransportData = {
      initialTariff,
      initialCommission,
      initialCompanyId
    }
    const externalTransportData: ExternalTransportData = {
      companyId: companyInfo?.id,
      repeatOnDates,
      intermPOIList,
      passengers,
      tariffInfo
    }
    const appFlowData: AppFlowData = { setIsLoading, history, templateName: templateInfo?.template?.name }
    const identificationData: IdentificationData = { businessProfileId, voucher }
    const transportPricingContext: TransportPricingContextType = {
      action: convertStringToTransferAction(action),
      originAgency: transport.taxBreakdownOriginAgency,
      transferType: transport.taxBreakdownTransferType
    }
    let submitFormProps: SubmitFormProps = {
      transport,
      initialTransportData,
      externalTransportData,
      appFlowData,
      identificationData,
      transportPricingContext,
      rentPlanData,
      getPriceByTariffInfoPopulated,
      interventionSubscription: checkedSubscription,
    }
    if (Boolean(recomputeStatus)) {
      submitFormProps.recomputeStatus = recomputeStatus
    }
    submitForm(submitFormProps)
  }

  return (
    <>
      <div
        className='card-field pb-7 card-field'
        style={{
          maxWidth: 280,
        }}
      >
        {modalVisible ? (
          <ModalPortal setVisible={setModalVisible} visible={modalVisible} hasExitButton={false}>
            <ConfirmModalWrapper
              setModalVisible={setModalVisible}
              blockedStatus={getBlockedStateFromTransport({
                blockTariff: transport.blockTariff && whichBreakdownChanged.tariffChanged && canPromptRecalculate,
                blockCommission:
                  transport.blockCommission && whichBreakdownChanged.commissionChanged && canPromptRecalculate,
              })}
              submitForm={({ recomputeTaxBreakdown, recomputeCommissionBreakdown }) => {
                const recomputeStatus: RecomputeStatus = { recomputeTaxBreakdown, recomputeCommissionBreakdown }
                prepareDataAndSubmit(recomputeStatus)
              }}
            />
          </ModalPortal>
        ) : null}
        <label className='form-label '>Current template:</label>
        <Select
          options={templates}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          onChange={(e) => {
            // redirect to the new template
            window.location.href = `/add-edit-transport/${getURLFormattedTemplateName(e.name)}${id ? '/' + id : ''
              }`
          }}
          value={templates.find(
            (template) => template.name.toLowerCase() === templateName.replaceAll('-', ' ')
          )}
        />
      </div>
      <div className='needs-validation form'>
        <div
          className='page__top-buttons'
          style={{
            position: 'fixed',
            display: 'flex',
            justifyContent: 'end',
            zIndex: 1000,
            right: '90px',
            top: '12px',
          }}
        >
          {Boolean(id) ? (
            <Link
              className='btn btn-light w-90px'
              style={linkStyle}
              to={`/view-transport/${templateName}/${id}`}
            >
              View
            </Link>
          ) : null}
          <button
            className='btn btn-light w-90px'
            style={{ background: 'none' }}
            onClick={(e) => {
              e.preventDefault()

              history.goBack()
            }}
            disabled={isLoading}
            type='button'
          >
            Cancel
          </button>
          <button
            className='btn btn-primary w-90px'
            onClick={(e) => {
              setIsLoading(true)
              e.preventDefault()
              const formValidationStatus = isFormValid(transport, customFields, passengers, templateInfo.flatFields)
              const formValidationFailed = Boolean(formValidationStatus.error)
              if (formValidationFailed) {
                setIsLoading(false)
                return;
              }
              // if recalculation is an option (it isn't a new request) then we verify if the commission or tariff changed
              if (canPromptRecalculate) {
                const hasTariffChanged =
                  transport?.tariff != initialTariff ||
                  !areObjectsEqual({
                    objectA: transport.taxBreakdown,
                    objectB: tariffInfo.taxBreakdown,
                  })
                const hasCommissionChanged =
                  transport?.commission != initialCommission ||
                  !areObjectsEqual({
                    objectA: transport.commissionBreakdown,
                    objectB: tariffInfo.commissionBreakdown,
                  })

                if (
                  (transport.blockTariff && hasTariffChanged) ||
                  (transport.blockCommission && hasCommissionChanged)
                ) {
                  setWhichBreakdownChanged({
                    tariffChanged: hasTariffChanged,
                    commissionChanged: hasCommissionChanged,
                  })
                  return setModalVisible(true)
                }
              }
              prepareDataAndSubmit()
            }}
            disabled={isLoading || isFormLoading}
            type='submit'
          >
            {(isLoading || isFormLoading) ? (
              <span
                style={{ width: '1.3rem', height: '1.3rem' }}
                className='spinner-border spinner-border-sm align-middle'
              />
            ) : (
              <span>Save</span>
            )}
          </button>
        </div>
        {templateInfo.template.panels.map((panel) => (
          <div key={'container' + panel.name} className='col-12 pb-4'>
            <Card key={'card' + panel.name} name={panel.name} isPlanCreated={true}>
              {panel.fields
                .map((field) => {
                  if (!field) return null
                  return (
                    <Field
                      key={panel.name + ' ' + (field?.name || '')}
                      name={field?.label || field?.name || ''}
                      style={{ ...field?.style, display: field?.hidden ? 'none' : '' }}
                      // className={field?.className || ''}
                      field={field}
                      generateTransportField={generateTransportField}
                      transport={transport}
                      setTransport={setTransport}
                      isFormLoading={isFormLoading}
                    />
                  )
                })}
            </Card>
          </div>
        ))}
      </div>
    </>
  )
}

const AddEditTransportWrapper: FC = () => {
  const intl = useIntl()
  const { templateName } = useParams<any>()
  const { id } = useParams<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [templateInfo, setTemplateInfo] = useState<TemplateInfoType | null>(null)
  const [extraTitle, setExtraTitle] = useState<string>('')
  const { getTemplates, setTemplates, templates } = useContext<any>(TemplatesContext)
  const { businessProfileId } = useContext<any>(UserContext)
  const { getPromiseByKey } = useContext<any>(AppContext)
  const [companies, setCompanies] = useState<any[]>([])
  const [suppliers, setSuppliers] = useState<any[]>([])
  const [departments, setDepartments] = useState<any[]>([])
  const [dictionaries, setDictionaries] = useState<any>({})
  const [drivers, setDrivers] = useState<any>([])
  const [tariffServices, setTariffServices] = useState<TariffServiceType[]>()
  const [tariffTypes, setTariffTypes] = useState<any[]>()
  const [VAT, setVAT] = useState<number>(0)
  const [exchangeRate, setExchangeRate] = useState<number>(0)
  const [vehicles, setVehicles] = useState<any[]>([])
  const [vehicleBrands, setVehicleBrands] = useState<any[]>([])
  const [vehicleTypes, setVehicleTypes] = useState<any[]>([])
  const history = useHistory()

  let transportIdForAction = null,
    action = null
  if (!isLoading) {
    // get params from url
    const search = new URLSearchParams(history.location.search)
    var integerReg = /^\d+$/
    // extract the transport id that will be used for the action
    transportIdForAction = search.get('transportIdForAction')
    if (transportIdForAction != null && !integerReg.test(transportIdForAction)) {
      // if we can't extract a valid number make it empty
      transportIdForAction = ''
    }
    // extract the action name of the case to be applied to the given transport
    action = search.get('action')
  }

  useEffect(() => {
    Promise.allSettled([
      (async () => {
        const result = await getPromiseByKey('companies', { businessProfileId })
        if (Boolean(result?.data) && Array.isArray(result?.data)) {
          const companiesList: any[] = result?.data
          const futureCompanies = []
          const futureSuppliers = []
          companiesList.forEach((company: any) => {
            if (company.client) {
              futureCompanies.push(company)
            } else {
              futureSuppliers.push(company)
            }
          })

          setCompanies(futureCompanies)
          setSuppliers(futureSuppliers)
        }
      })(),
      (async () => {
        const result = await getPromiseByKey('departments', { businessProfileId })
        if (Boolean(result?.data)) {
          setDepartments(result.data)
        }
      })(),
      (async () => {
        const result = await getPromiseByKey('dictionaries')
        if (Boolean(result?.data)) {
          setDictionaries(result.data)
        }
      })(),
      (async () => {
        const result = await getPromiseByKey('drivers', { businessProfileId })
        if (Boolean(result?.data)) {
          setDrivers(result.data)
        }
      })(),
      (async () => {
        const result = await getPromiseByKey('tariffServices', { businessProfileId })
        if (Boolean(result?.data)) {
          setTariffServices(result.data)
        }
      })(),
      (async () => {
        const result = await getPromiseByKey('tariffTypes', { businessProfileId })
        if (Boolean(result?.data)) {
          setTariffTypes(result.data)
        }
      })(),
      (async () => {
        const result = await getPromiseByKey('vehicles')
        if (Boolean(result?.data)) {
          setVehicles(result.data)
        }
      })(),
      (async () => {
        const result = await getPromiseByKey('vehicleBrands')
        if (Boolean(result?.data)) {
          setVehicleBrands(result.data)
        }
      })(),
      (async () => {
        const result = await getPromiseByKey('vehicleTypes')
        if (Boolean(result?.data)) {
          setVehicleTypes(result.data)
        }
      })(),
      (async () => {
        const result = await getTemplates()
        const receivedTemplates = result?.data || []
        if (!templates || (Array.isArray(templates) && templates.length === 0)) {
          setTemplates(receivedTemplates)
        }

        let template = receivedTemplates.find((template: any) => {
          const formatedTemplateName = getURLFormattedTemplateName(template.name)
          return formatedTemplateName === templateName
        })

        
        const fieldsCopy = JSON.parse(JSON.stringify(fields))

        if (!template) {
          toast.error(
            'The provided template was not found: switched to the default template configuration'
          )
          let defaultTemplateInfo = getTemplateInfoObject(fieldsCopy, defaultTemplate, businessProfileId)
          setTemplateInfo(defaultTemplateInfo)
          return null
        }

        if (typeof template.content === 'string') {
          template.content = JSON.parse(template.content)
        }
        if (!template.content) {
          toast.error(
            'The provided template was not found: switched to the default template configuration'
          )
          let defaultTemplateInfo = getTemplateInfoObject(fieldsCopy, defaultTemplate, businessProfileId)
          setTemplateInfo(defaultTemplateInfo)
          return null
        }

        const futureTemplate = template.content
        const futureTemplateInfo = getTemplateInfoObject(fieldsCopy, futureTemplate, businessProfileId)
        setTemplateInfo(futureTemplateInfo)
      })(),
      (async () => {
        // set VAT
        const result = await easytrackAxios
          .get(`${EASYTRACK_API_URL}/utils/constants`)
          .catch((err) => err)
        if (Boolean(result?.data)) {
          setVAT(result.data?.VAT)
        }
      })(),
      (async () => {
        const result = await easytrackAxios
          .get(`${EASYTRACK_API_URL}/utils/exchange-rates/EUR`)
          .catch((err) => err)
        if (Boolean(result?.data)) {
          setExchangeRate(result.data)
        }
      })(),
    ]).then((e) => {
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      <PageTitle subtitle={capitalize(templateName).replaceAll('-', ' ')}>
        {id
          ? `Edit transport ${extraTitle}`
          : getAddEditTransportTitlePageForAction({ action, transportIdForAction })}
      </PageTitle>

      {isLoading || !templateInfo || !templates ? (
        <LoadingCard />
      ) : (
        <AddEditTransportPage
          companies={companies}
          suppliers={suppliers}
          departments={departments}
          dictionaries={dictionaries}
          drivers={drivers}
          tariffServices={tariffServices}
          tariffTypes={tariffTypes}
          templateName={templateName}
          templateInfo={templateInfo}
          VAT={VAT}
          vehicles={vehicles}
          vehicleBrands={vehicleBrands}
          vehicleTypes={vehicleTypes}
          exchangeRate={exchangeRate}
          setExtraTitle={setExtraTitle}
          transportIdForAction={transportIdForAction}
          action={action}
        />
      )}
    </>
  )
}

const getAddEditTransportTitlePageForAction = ({
  action,
  transportIdForAction,
}: {
  action: string
  transportIdForAction: number
}) => {
  if (!action || !transportIdForAction) {
    return 'Add transport'
  }
  if (action === 'repeat') {
    return `Add Repeat for transport with id ${transportIdForAction}`
  } else if (action === 'return') {
    return `Add Return for transport with id ${transportIdForAction}`
  } else {
    return 'Add transport'
  }
}

export default AddEditTransportWrapper
