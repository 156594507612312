import React, { useEffect } from 'react'
import { VehicleType } from '../../../../../types/vehicle.types'
import { ClientType, TypeOfClient } from '../../../../../types/client.types'
import { SubscriptionType } from '../../../../../types/subscription.types'
import { PackageType } from '../../../../../types/company.types'
import ModalPortal from '../../../../../_metronic/layout/components/modal/modal-portal'
import { anyToBoolean } from '../../../../../utils/string.utils'
import { toast } from 'react-toastify'

const DisplayRow: React.FC<{
  label: string
  value: any
  isBinary?: boolean
  isLast?: boolean
}> = ({ label, value, isBinary = false, isLast = false }) => {
  const content = isBinary ? (value ? 'YES' : 'No') : (value || '-')
  const labelStyle = {
    display: 'inline-block',
    width: '40%',
    maxWidth: '80%',
    padding: '0.75rem'
  }
  const contentStyle = {
    display: 'inline-block',
    width: 'auto',
    padding: '0.75rem'
  }
  return (
    <div key={`row-${label}`} style={{ display: 'flex', borderBottom: isLast ? '' : '1px solid #f5f8fa' }}>
      <div style={labelStyle}>
        <span className='fw-bold'>{label}</span>
      </div>

      <div style={contentStyle}>
        <span>{content}</span>
      </div>
    </div>
  )
}

const HeaderRow = ({ title }: { title: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
        paddingTop: '1rem',
        paddingBottom: '0.5rem',
        borderBottom: '3px solid rgb(245, 248, 250)'
      }}>
      <h4 style={{ paddingLeft: '0.75rem' }}>{title}</h4>
    </div>
  )
}

const SubscriptionPanel = ({ subscription }: { subscription: SubscriptionType }) => {
  const companyName = subscription?.company?.name
  const mainCompanyName = subscription?.main_company_name || companyName
  if (!subscription) {
    return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h2>Missing Subscription Info!</h2>
    </div>
  }
  return <div style={{ display: 'flex', flexFlow: 'column' }}>
    <HeaderRow title={'Subscription'} />
    <DisplayRow label={'Company'} value={mainCompanyName} />
    <DisplayRow label={'Dealer'} value={companyName} />
    <DisplayRow label={'Payment Type'} value={subscription?.payment_type} />
    <DisplayRow label={'Status'} value={subscription?.status} />
    <DisplayRow label={'Package'} value={subscription?.package?.name} />
    <DisplayRow label={'Bought At'} value={subscription?.bought_at} />
    <DisplayRow label={'Activation Date'} value={subscription?.activated_at} />
    <DisplayRow label={'Expiration Date'} value={subscription?.expiration_date} />
    <DisplayRow label={'Policy Number'} value={subscription?.policy_number} isLast={true} />
  </div>
}

const PackageInfoPanel = ({ packageInfo }: { packageInfo: PackageType }) => {
  if (!packageInfo) {
    return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h2>Missing Package Info!</h2>
    </div>
  }
  return <div style={{ display: 'flex', flexFlow: 'column' }}>
    <HeaderRow title={'Package'} />
    <DisplayRow label={'Name'} value={packageInfo?.name} />
    <DisplayRow label={'Price'} value={packageInfo?.price} />
    <DisplayRow label={'Currency'} value={packageInfo?.currency} />
    <DisplayRow label={'Valability Period'} value={packageInfo?.valability_period} />
    <DisplayRow label={'Valability Unit'} value={packageInfo?.valability_unit} />
    <DisplayRow label={'Package Summary'} value={packageInfo?.package_summary} isLast={true} />
  </div>
}

const VehiclePanel = ({ vehicle }: { vehicle: VehicleType }) => {
  if (!vehicle) {
    return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h2>Missing Vehicle Info!</h2>
    </div>
  }
  return <div style={{ display: 'flex', flexFlow: 'column' }}>
    <HeaderRow title={'Vehicle'} />
    <DisplayRow label={'Red Number'} value={anyToBoolean(vehicle?.red_number)} isBinary={true} />
    <DisplayRow label={'Registration Number'} value={vehicle?.registration_nr} />
    <DisplayRow label={'VIN'} value={vehicle?.vin} />
    <DisplayRow label={'Brand'} value={vehicle?.brand} />
    <DisplayRow label={'Model'} value={vehicle?.model} />
    <DisplayRow label={'Category'} value={vehicle?.category} />
    <DisplayRow label={'Registration Date'} value={vehicle?.registration_date} />
    <DisplayRow label={'Weight'} value={vehicle?.weight} isLast={true} />
  </div>
}

const ClientPanel = ({ client }: { client: ClientType }) => {
  if (!client) {
    return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h2>Missing Client Info!</h2>
    </div>
  }
  const isJuridical = client?.type === TypeOfClient.PJ
  return <div style={{ display: 'flex', flexFlow: 'column' }}>
    <HeaderRow title={'Client'} />
    <DisplayRow label={'Type Of Person'} value={client?.type} />
    <DisplayRow label={isJuridical ? 'Company Name' : 'Owner'} value={client?.insurance_for} />
    <DisplayRow label={isJuridical ? 'Name' : 'User'} value={client?.name} />
    <DisplayRow label={'Email'} value={client?.email} />
    <DisplayRow label={'Phone'} value={client?.phone} />
    {isJuridical ? <DisplayRow label={'CUI'} value={client?.identity_code} /> :  <DisplayRow label={'CNP'} value={client?.identity_code} />}
    <DisplayRow label={'Address'} value={client?.address} isLast={true} />
  </div>
}

const PanelContainer = ({ children }) => {
  return <div
    style={{
      minHeight: '400px',
      display: 'inline-block',
      minWidth: '42.5%',
      width: '42.5%',
      border: '1px solid #E4E6EF',
      borderRadius: '0.475rem',
      marginBottom: '1rem',
      backgroundColor: 'white',
      overflow: 'auto',
    }}>
    {children}
  </div>
}

const ModalContentContainer = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: '-1rem'
      }}
    >
      {children}
    </div>
  )
}

const ContentBody = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        paddingTop: '1rem',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-evenly',
        background: '#f3f7f9'
      }}
    >
      {children}
    </div>
  )
}

const DisplaySubscriptionModal = ({
  subscription,
  modalVisible,
  setModalVisible
}: {
  subscription: SubscriptionType
  modalVisible: boolean
  setModalVisible: Function
}) => {
  useEffect(() => {
    if (!subscription) {
      toast.error("Couldn't open the subscription!")
    }
  }, [subscription?.id])

  if (!subscription) {
    return null
  }

  return (
    <ModalPortal
      setVisible={setModalVisible}
      visible={modalVisible}
      dialogClassName='modal-xxl'
      hasExitButton={true}
      title={'Display Subscription Info'}
    >
      <ModalContentContainer>
        <ContentBody>
          <PanelContainer><SubscriptionPanel subscription={subscription} /></PanelContainer>
          <PanelContainer><PackageInfoPanel packageInfo={subscription?.package} /></PanelContainer>
          <PanelContainer><VehiclePanel vehicle={subscription?.vehicle} /></PanelContainer>
          <PanelContainer><ClientPanel client={subscription?.client} /></PanelContainer>
        </ContentBody>
      </ModalContentContainer>
    </ModalPortal>
  )
}

export default DisplaySubscriptionModal