import { FieldType } from '../utils/field.utils';
import { ClientType } from './client.types';
import { CompanyType, PackageType, emptyCompany, emptyPackage } from './company.types';
import { VehicleType } from './vehicle.types';

export type UserCompanyType = {
  companyId: number;
  mainCompanyId: number;
  mainCompanyName: string;
  mainCompanyStripePayable: boolean;
  isVinRequired: boolean;
  companyName: string;
};

export enum SubscriptionStatus {
  Incomplete = 'incomplete',
  Inactive = 'inactive',
  Active = 'active',
  Cancelled = 'cancelled',
  Expiring = 'expiring',
  Expired = 'expired',
  Pending = 'pending',
}
export const statusList = Object.values(SubscriptionStatus).map((status: string) => ({
  name: status,
}));
export const stripeStatusRefund = 'payment return';
export const statusRefund = 'cancelled';
export const isOfStripeStatusRefunded = (status: string) => {
  const formattedStatus = (status || '')?.trim().toLowerCase();
  return formattedStatus === 'refunded' || formattedStatus === stripeStatusRefund;
};
export const stripeStatusPaid = 'payment confirmed';
export const isOfStripeStatusPaid = (status: string) => {
  const formattedStatus = (status || '')?.trim().toLowerCase();
  return formattedStatus === 'online paid' || formattedStatus === stripeStatusPaid;
};

export type SubscriptionType = {
  id: number;
  vehicle: VehicleType;
  package: PackageType;
  company: CompanyType;
  client: ClientType;
  vehicle_id: number;
  package_id: number;
  company_id: number;
  client_id: number;
  status: string;
  cancelled_status_reason: string;
  payment_type: PaymentType;
  interface_type?: string;
  newsletter?: boolean;
  policy_number?: string;
  road_intervention?: boolean;
  intervention_time?: string;
  subscription_type?: PaymentType;
  stripe_status?: string;
  main_company_stripe_payable?: boolean;
  main_company_name?: string;
  created_by_user: {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
  };
  bought_at: Date;
  activated_at: Date;
  expiration_date: Date;
  created_at?: Date;
  discount?: number;
  price_paid?: number;
};

export enum PaymentType {
  STRIPE = 'stripe',
  CONTRACT = 'contract',
}
export const paymentTypes = ['stripe', 'contract'];

export type FlatSubscriptionType = {
  id: number;
  activated_at: Date;
  expiration_date: Date;
  created_at: Date;
  package_name: string;
  client_email: string;
  client_phone: string;
  client_name: string;
  registration_nr: string;
  brand: string;
  model: string;
  red_number: string;
  company_name: string;
  vin: string;
  status: string;
  stripe_status?: string;
  stripe_payable?: boolean;
  client_identity_code?: string;
  payment_type: PaymentType;
  campaign_name?: string;
  interface_type?: string;
  newsletter?: boolean;
  vehicle_age?: string;
  created_by: number;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  company_id: number;
  main_company_id: number;
  main_company_name?: string;
  package_id: number;
  discount?: number;
  price_paid?: number;
  policy_number?: string;
  road_intervention?: boolean;
  intervention_time?: string;
};

export const emptyUser = {
  id: 0,
  firstName: '',
  lastName: '',
  username: '',
  email: '',
};
export const emptySubscription: SubscriptionType = {
  id: 0,
  vehicle: null,
  package: emptyPackage,
  company: emptyCompany,
  client: null,
  vehicle_id: 0,
  package_id: 0,
  company_id: 0,
  client_id: 0,
  payment_type: PaymentType.CONTRACT,
  interface_type: 'CB',
  newsletter: false,
  status: SubscriptionStatus.Inactive,
  cancelled_status_reason: null,
  created_by_user: emptyUser,
  bought_at: null,
  activated_at: null,
  expiration_date: null,
};

export type CarModel = {
  id: number;
  make_name: string;
  model_name: string;
};

export type SubscriptionFilterValue = {
  searchInput: string;
  selectedStatus: string;
  selectedCompanies: CompanyType[];
  selectedTimeInterval: string[];
  selectedCampaigns: {id: number, name: string}[];
};

export type SubscriptionFilterSetter = {
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCompanies: React.Dispatch<React.SetStateAction<CompanyType[]>>;
  setSelectedTimeInterval: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCampaigns: React.Dispatch<React.SetStateAction<{id: number, name: string}[]>>;
};

export type SubscriptionsContextType = {
  subscriptionCompanies: CompanyType[];
  setSubscriptionCompanies: React.Dispatch<React.SetStateAction<CompanyType[]>>;
  importRules: string;
  setImportRules: React.Dispatch<React.SetStateAction<string>>;
  mainSubscriptionCompanies: CompanyType[];
  carModels: CarModel[];
  setCarModels: React.Dispatch<React.SetStateAction<CarModel[]>>;
  getCarModels: (type: string) => void;
  subscriptionFields: FieldType[];
  setSubscriptionFields: React.Dispatch<React.SetStateAction<FieldType[]>>;
  clientFields: FieldType[];
  setClientFields: React.Dispatch<React.SetStateAction<FieldType[]>>;
  vehicleFields: FieldType[];
  setVehicleFields: React.Dispatch<React.SetStateAction<FieldType[]>>;
  filter: SubscriptionFilterValue;
  setFilter: SubscriptionFilterSetter;
};
