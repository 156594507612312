import {useContext, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {TransportsContext} from '../../../../../context/transports.context'
import Loading from '../../../../../_metronic/layout/components/loading/Loading'
import DateInput from '../../../../../_metronic/layout/components/fields/date-input/date-input-field'
import {DateObject} from 'react-multi-date-picker'
import moment from 'moment'
import {SelectObject} from '../../../../../_metronic/layout/components/select/Select'
import {API_URL, EASYTRACK_API_URL} from '../../../../../constants/api.constants'
import {UserContext} from '../../../../../context/user.context'
import easytrackAxios from '../../../../../setup/easytrack.axios'
import customAxios from '../../../../../setup/custom.axios'
import {Button} from 'react-bootstrap'
import {EMPTY_INVOICE_NECESSARIES, Invoice, InvoiceNecessaries} from '../../../../../types/invoice.types'
import {calculateNextInvoiceNumber, getMappedNeededItems, getMappedRates, getNeededLineItems} from '../../../../../utils/invoice.utils'
import { isWeekDay } from '../../../../../utils/date.utils'

const SendInvoiceModal = ({
  transports,
  setModalVisible,
  onCancelModal,
  markInvoice,
  exportPDF,
  areAllTransportsFulfilled,
  setTransports,
  selectedTransports
}: {
  transports: any[]
  markInvoice: Function
  setModalVisible: (modalVisible: boolean) => void
  allTransportsSelected: boolean
  onCancelModal: Function
  exportPDF: (s: boolean) => Promise<any>,
  areAllTransportsFulfilled: boolean,
  setTransports: Function,
  selectedTransports: any[]
}) => {
  const {pageCount, selectedCompanies, selectedTimeInterval} = useContext<any>(TransportsContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [items, setItems] = useState([])
  const [predefinedItemDescription, setPredefinedItemDescription] = useState<string>(
    ', efectuate în perioada xx/xx/xxxx - xx/xx/xxxx conform anexa. '
  )

  const [predefinedItemDescriptionHasBeenChecked, setPredefinedItemDescriptionHasBeenChecked] =
    useState<boolean>(false)

  const [exchangeRate, setExchangeRate] = useState<number>()
  const {user, jwt} = useContext<any>(UserContext)
  const [isNewInvoiceSend, setIsNewInvoiceSend] = useState<boolean>(false)
  const [invoiceNumberComparer, setInvoiceNumberComparer] = useState<{
    lastInvoiceCreated: InvoiceNecessaries
    lastCreditNoteCreated: string
  }>({
    lastInvoiceCreated: EMPTY_INVOICE_NECESSARIES,
    lastCreditNoteCreated: '',
  })

  const [invoice, setInvoice] = useState<Invoice>({
    orderNumber: '',
    date: moment().format('YYYY-MM-DD'),
    items: [],
    itemDescription: [],
    invoiceNumber: '',
    attach: false,
  })

  const getZohoItems = async () => {
    try {
      setIsLoading(true)
      const response = await customAxios.get(`${API_URL}/zoho/getItems`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
          'user-id': user.id,
        },
      })
      if (response.data.error) {
        toast.error('Error while getting items from zoho')
        setIsLoading(false)
        return
      }
      setItems(response.data.items)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      toast.error(err?.response?.data?.message || 'Something went wrong')
    }
  }

  const getCreditNotes = async () => {
    try {
      setIsLoading(true)
      const descendingCreditNotes = await customAxios.get(
        `${API_URL}/zoho/getDescendingSortedCreditNotes`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
            'user-id': user.id,
          },
        }
      )

      const descendingInvoices = await customAxios.get(
        `${API_URL}/zoho/getDescendingSortedInvoices`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
            'user-id': user.id,
          },
        }
      )

      if (!descendingCreditNotes || !descendingCreditNotes.data) {
        toast.error('Credit notes do not exist')
        return
      }

      const creditNotes = descendingCreditNotes.data.credit_notes;

      if (
        !creditNotes ||
        creditNotes.length === 0
      ) {
        toast.error('No credit notes were found')
        return
      }

      if (creditNotes[0]?.code) {
        toast.error(creditNotes[0].message)
        setIsLoading(false)
        return
      }

      if (!descendingInvoices || !descendingInvoices.data) {
        toast.error('Something went wrong')
        return
      }
 
      const invoices = descendingInvoices.data.invoice;
      if (!invoices || invoices.length === 0) {
        toast.error('No invoices were found')
        return
      }

      if (invoices[0]?.code) {
        toast.error(invoices[0].message)
        setIsLoading(false)
        return
      }
      
      const invoiceInvoices = invoices[0]?.invoices;
      const creditNoteInvoices = creditNotes[0]?.creditnotes;

      setInvoiceNumberComparer({
        lastInvoiceCreated:
          Array.isArray(invoiceInvoices) &&
          invoiceInvoices.length
            ? {
                invoice_id: invoiceInvoices.filter(invoice => invoice.invoice_number.startsWith('VMS-'))[0]?.invoice_id,
                invoice_number: invoiceInvoices.filter(invoice => invoice.invoice_number.startsWith('VMS-'))[0]?.invoice_number,
              }
            : EMPTY_INVOICE_NECESSARIES,
        lastCreditNoteCreated:
          Array.isArray(creditNoteInvoices) &&
          creditNoteInvoices.length
            ? creditNoteInvoices.filter(credit_note => credit_note.creditnote_number.startsWith('VMS-'))[0]?.creditnote_number
            : '',
      })
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      toast.error(err?.response?.data?.message || 'Something went wrong')
    }
  }

  const getCurrentExchangeRate = async () => {
    const result = await easytrackAxios
      .get(`${EASYTRACK_API_URL}/utils/exchange-rates/EUR/${invoice.date}`)
      .catch((err) => err)
    setExchangeRate(result.data)
  }

  const checkPredefinedItemDescription = () => {
    if (selectedTimeInterval?.length === 2) {
      setPredefinedItemDescription(
        `, efectuate în perioada ${selectedTimeInterval[0]} - ${moment(selectedTimeInterval[1]).subtract(1, 'days').format('YYYY/MM/DD')} conform anexa. `
      )
    } else if (selectedTimeInterval?.length === 1) {
      setPredefinedItemDescription(
        `, efectuate în perioada ${selectedTimeInterval[0]} - ${selectedTimeInterval[0]} conform anexa. `
      )
    } else {
      setPredefinedItemDescription(
        ', efectuate în perioada xx/xx/xxxx - xx/xx/xxxx conform anexa. '
      )
    }

    setPredefinedItemDescriptionHasBeenChecked(true)
  }

  //the "onSendWithAttach" function is used when the user wants to attach the transport report to the invoice
  //it returns the pdf file and the line items that will be used to create the invoice
  //there are only 2 types of items: rent a car and other transports
  //the price for each item is calculated by multiplying the number of transports with the exchange rate at the date selected to create the invoice with

  const onSendWithAttach = async (neededLineItems: any[]) => {
    const pdf = await exportPDF(true)

    const rentACarTransports =
      pdf.transportStatistics.filter(
        (transport) => transport.transportTariffService === 'Rent a Car' && transport.transportPaymentType === 'CONTRACT'
      ) || []

    const busMinibusTransports =
      pdf.transportStatistics.filter(
        (transport) => (transport.transportTariffService === 'Bus' || transport.transportTariffService === 'Minibus') && transport.transportPaymentType === 'CONTRACT'
      ) || []

    const otherTransports =
      pdf.transportStatistics.filter(
        (transport) => transport.transportTariffService !== 'Rent a Car' && 
          transport.transportTariffService !== "Bus" &&
          transport.transportTariffService !== 'Minibus' && 
          transport.transportPaymentType === 'CONTRACT'
      ) || []

    const rentACarTransportsSum = rentACarTransports.length
      ? rentACarTransports.map((rent) => rent.transportTariff).reduce((a, b) => (a = a + b))
      : 0

    const busMinibusTransportsSum = busMinibusTransports.length
      ? busMinibusTransports.map((rent) => rent.transportTariff).reduce((a, b) => (a = a + b))
      : 0

    const otherTransportsSum = otherTransports.length
      ? otherTransports.map((rent) => rent.transportTariff)?.reduce((a, b) => (a = a + b))
      : 0

    let item1Price = parseFloat((rentACarTransportsSum).toFixed(2));
    let item2Price = parseFloat((otherTransportsSum).toFixed(2));
    let item3Price = parseFloat((busMinibusTransportsSum).toFixed(2));

    if(rentACarTransports.length && rentACarTransports.every(rentTransport => rentTransport.transportCurrency === 'EUR')) {
      item1Price = parseFloat((rentACarTransportsSum * exchangeRate).toFixed(2))
    }
    
    if(otherTransports.length && otherTransports.every(otherTransport => otherTransport.transportCurrency === 'EUR')) {
      item2Price = parseFloat((otherTransportsSum * exchangeRate).toFixed(2))
    }

    if(busMinibusTransports.length && busMinibusTransports.every(busMinibusTransport => busMinibusTransport.transportCurrency === 'EUR')) {
      item3Price = parseFloat((busMinibusTransportsSum * exchangeRate).toFixed(2))
    }
    
    const arr = [item1Price, item2Price, item3Price]
    const rates = getMappedRates(arr, items)

    const lineItems = getMappedNeededItems({
      neededLineItems,
      rates,
      invoice,
    })
    return {file: pdf.file, lineItems}
  }

  useEffect(() => {
    checkPredefinedItemDescription()
  }, []);

  useEffect(() => {
    if (predefinedItemDescriptionHasBeenChecked) {
      getZohoItems()
      getCreditNotes()
    }
  }, [predefinedItemDescriptionHasBeenChecked])

  useEffect(() => {
    getCurrentExchangeRate()
  }, [invoice?.date])

  const onChange = (field: keyof Invoice, value: string | DateObject | File | boolean | any[], field2?: typeof field, value2?: typeof value) => {
    setInvoice({...invoice, [field]: value, [field2]: value2})
  }

  const onSend = async () => {
    setIsLoading(true)

    const finalInvoiceNumber = calculateNextInvoiceNumber(invoiceNumberComparer)

    if (!invoice.date) {
      toast.error('Please provide a valid invoice date!')
      setIsLoading(false)
      return
    }
    if (!invoice.items.length) {
      toast.error('Please provide an item to your invoice!')
      setIsLoading(false)
      return
    }

    const bodyFormData = new FormData()
    bodyFormData.append('invoice_number', invoice.invoiceNumber)
    bodyFormData.append('reference_number', invoice.orderNumber)
    bodyFormData.append('date', moment(invoice.date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
    bodyFormData.append(
      'notes',
      `Curs valutar 1 EUR = ${exchangeRate} RON`
    )
    if (selectedCompanies[0].zohoId !== undefined) {
      bodyFormData.append('customer_id', selectedCompanies[0].zohoId)
    } else {
      toast.error('Selected company has no zoho customer id associated!')
      setIsLoading(false)
      return
    }

    const neededLineItems = getNeededLineItems(invoice)

    // check if attachement exists
    if (invoice.attach) {
      const {file: pdf, lineItems} = await onSendWithAttach(neededLineItems)
      bodyFormData.append('attachment', pdf)
      bodyFormData.append('line_items', JSON.stringify(lineItems))
    } else {
      const lineItems = neededLineItems.map((item, index) => ({
        ...item,
        description: invoice.itemDescription[index],
      }))
      bodyFormData.append('line_items', JSON.stringify(lineItems))
    }
    bodyFormData.append('invoice_number_startswith', 'VMS-')

    try {
      if (invoiceNumberComparer.lastCreditNoteCreated) {
        const finalInvoiceNumber = calculateNextInvoiceNumber(invoiceNumberComparer)
        bodyFormData.append('invoice_number', `VMS-${finalInvoiceNumber}`)
        setInvoice({...invoice, invoiceNumber: `VMS-${finalInvoiceNumber}`})
      }

      const response = await customAxios.post(`${API_URL}/zoho/createInvoice`, bodyFormData, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'multipart/form-data',
          'user-id': user.id,
        },
      })

      if (response.data.invoice[0].code) {
        toast.error(response.data.invoice[0].message)
        setIsLoading(false)
        return
      }
      await getCreditNotes()
      setIsNewInvoiceSend(true)
      setIsLoading(false)
      await markInvoice(`VMS-${finalInvoiceNumber}`)
      setModalVisible(false);
      const transportsUpdated = transports.map((transport) => ({
        ...transport,
        invoiceNumber: selectedTransports.find((selectedTransport) => selectedTransport === transport.id)
          ? `VMS-${finalInvoiceNumber}`
          : transport.invoiceNumber
      }))
      setTransports([...transportsUpdated])
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Something went wrong')
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return (
      <div className='d-flex flex-column' style={{width: '80%'}}>
        <Loading />
      </div>
    )
  }
  return (
    <div className='d-flex flex-column' style={{width: '80%'}}>
      <div className='modal-header' style={{display: 'flex', justifyContent: 'center'}}>
        <p className='fs-2 text-center modal-title'>Send invoice</p>
      </div>

      <form className='d-flex modal-body flex-column justify-content-between align-items-between gap-4 fs-6 pb-0'>
        <label className='form-label'>
          Set invoice for: {selectedCompanies.length > 0 ? selectedCompanies[0].name : ''}
        </label>
        <div>
          <label className='form-label'>Order number:</label>
          <input
            placeholder='Enter order number...'
            className='form-control'
            type='text'
            value={invoice.orderNumber}
            onChange={(e) => onChange('orderNumber', e.target.value)}
          />
        </div>
        <div>
          <DateInput
            label={'Invoice date'}
            field={null}
            dateFormat='YYYY-MM-DD'
            value={invoice.date}
            noDatePlugins
            onChange={(e) => {
              onChange('date', e.toString())
            }}
          />
        </div>
        <label className='form-label'>Items:</label>
        <SelectObject
          key='item-selector'
          isMulti
          styles={{
            menuPortal: (base: any) => ({...base, zIndex: 9999}),
          }}
          className='col-12'
          onChange={(e: any) => {
            onChange('items', e, 'itemDescription', e.map(item => item.description + predefinedItemDescription));
          }}
          simpleValue={invoice.items}
          value={invoice.items}
          options={items.map((item) => ({name: item, title: item.name}))}
          getOptionValue={(e: any) => e.name || ''}
          getOptionLabel={(e: any) => e.title || ''}
        />
        {invoice?.items?.map((item, index) => (
          <div key={item.item_id} style={{marginTop: 8, marginBottom: 8}}>
            <label className='form-label'>Item {item.name} description:</label>
            <textarea
              rows={5}
              style={{resize: 'none', overflow: 'hidden'}}
              placeholder='Enter item description...'
              className='form-control'
              value={invoice.itemDescription[index]}
              onChange={(e) => {
                invoice.itemDescription[index] = e.target.value
                onChange('itemDescription', invoice.itemDescription)
              }}
            />
          </div>
        ))}

        <div style={{marginTop: 8, marginBottom: 8}}>
          <label className='form-label'>Customer notes:</label>
          <span>
            {' '}
            Curs BNR{' '}
            {isWeekDay(
              moment(invoice.date, 'YYYY-MM-DD').day(),
              moment(invoice.date, 'YYYY-MM-DD')
            ).format('DD-MM-YYYY')}{' '}
            {exchangeRate}
          </span>
        </div>
        <div style={{marginTop: 8, marginBottom: 8}}>
          <span style={{marginRight: 16}}>
            Do you want the transport report to be attached to the invoice?
          </span>
          <input
            name={'attach'}
            className='clickable form-check-input me-2'
            type='checkbox'
            required={false}
            id={'attach'}
            onChange={(e) => {
              onChange('attach', e.target.checked)
            }}
            checked={invoice.attach}
          />
        </div>
        <div className='modal-footer d-flex justify-content-center mt-7'>
          <Button
            style={{width: 150, fontSize: 12, marginRight: 5, height: 50}}
            className='btn btn-primary'
            onClick={async () => await onSend()}
          >
            Send
          </Button>
          <Button
            style={{width: 150, fontSize: 12, height: 50}}
            disabled={!isNewInvoiceSend}
            className='btn btn-primary'
            onClick={async () => {
              try {
                const downloadInvoiceResponse = await customAxios.get(
                  `${API_URL}/zoho/downloadInvoice?invoice_id=${invoiceNumberComparer.lastInvoiceCreated.invoice_id}`,
                  {
                    responseType: 'blob',
                    headers: {
                      Authorization: `Bearer ${jwt}`,
                      'user-id': user.id,
                    },
                  }
                )

                if (
                  downloadInvoiceResponse.request.responseType === 'blob' &&
                  downloadInvoiceResponse.data instanceof Blob &&
                  downloadInvoiceResponse.data.type &&
                  downloadInvoiceResponse.data.type.toLowerCase().indexOf('json') != -1
                ) {
                  const errorString = JSON.parse(await downloadInvoiceResponse.data.text())
                  if (errorString.code) {
                    toast.error(errorString.message)
                    setIsLoading(false)
                    return
                  }
                }

                const urlZoho = window.URL.createObjectURL(
                  new Blob([downloadInvoiceResponse.data], {type: 'application/pdf'})
                )
                const linkZoho = document.createElement('a')
                linkZoho.href = urlZoho
                linkZoho.setAttribute(
                  'download',
                  `invoice_${invoiceNumberComparer.lastInvoiceCreated.invoice_id}` + '.pdf'
                )
                document.body.appendChild(linkZoho)
                linkZoho.click()

                const pdfCremee = await exportPDF(true)
                const urlCremee = window.URL.createObjectURL(
                  new Blob([pdfCremee.file], {type: 'application/pdf'})
                )
                const linkCremee = document.createElement('a')
                linkCremee.href = urlCremee
                linkCremee.setAttribute('download', `${pdfCremee.file.name}`)
                document.body.appendChild(linkCremee)
                linkCremee.click()
              } catch (err) {
                toast.error(err?.response?.data?.message || 'Something went wrong')
              }
            }}
          >
            Download invoice
          </Button>
        </div>
        <div>
          <label className='form-label'>Invoice number:</label>
          <input
            placeholder='Enter invoice number...'
            className='form-control'
            type='text'
            value={invoice.invoiceNumber}
            onChange={(e) => onChange('invoiceNumber', e.target.value)}
          />
        </div>
        <div
          className='btn btn-primary'
          onClick={async () => {
            setIsLoading(true)
            if (!invoice.invoiceNumber) {
              toast.error('Please provide an invoice number!')
              setIsLoading(false)
              return
            }
            await markInvoice(invoice.invoiceNumber)
            setIsLoading(false)
          }}
        >
          Mark
        </div>
        {areAllTransportsFulfilled && <div>
          <input
              name={'marked-successfully'}
              className='clickable form-check-input me-2'
              type='checkbox'
              disabled
              id={'marked-successfully'}
              checked={true}
            />
          <span>The invoice was marked successfully</span>
        </div>}
      </form>

      <div className='modal-footer d-flex justify-content-center mt-7'>
        <div
          className='btn btn-danger w-100'
          onClick={() => {
            onCancelModal()
            setModalVisible(false)
          }}
        >
          Cancel
        </div>
      </div>
    </div>
  )
}


export default SendInvoiceModal
