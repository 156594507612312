import React, { MouseEventHandler, useCallback, useState } from 'react'
import { SubscriptionType } from '../../../../../types/subscription.types'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG'
import { EditableLabel } from '../../../add-edit-template/components/fields-generator'
import moment from 'moment'
import DisplaySubscriptionModal from './DisplaySubscriptionModal'
import { FieldType } from '../../../../../utils/field.utils'

const iconStyle = {
  width: '22px',
  height: '22px',
  marginTop: 4,
  marginBottom: 4,
}

const SubscriptionRow = ({
  subscription,
  handleSelect,
  index,
  showCheckbox,
  checkedSubscription,
  setCheckedSubscription,
}: {
  subscription: SubscriptionType,
  handleSelect: MouseEventHandler<HTMLButtonElement>,
  index: number,
  showCheckbox?: boolean,
  checkedSubscription?: SubscriptionType | null,
  setCheckedSubscription?: (checkedSubscription: SubscriptionType | null) => void,
}) => {
  if (!subscription) {
    return null;
  }
  const status = subscription?.status || 'unknown'

  const vehicle = subscription?.vehicle
  const brand = vehicle?.brand || ''
  const model = vehicle?.model || ''

  const client = subscription?.client
  const owner = client?.insurance_for || ''
  const user = client?.name || ''

  const packageName = subscription?.package?.name || ''
  const activationDate = subscription?.activated_at ? moment(subscription?.activated_at).format('YYYY-MM-DD') : ''
  const expirationDate = subscription?.expiration_date ? moment(subscription?.expiration_date).format('YYYY-MM-DD') : ''
  const valabilityInterval = `${activationDate} - ${expirationDate}`

  return <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', backgroundColor: (index % 2 === 0) ? "#f5f8fa" : "white", padding: '16px' }}>
    {showCheckbox && <input
      className='clickable form-check-input'
      type='checkbox'
      onChange={() => {
        if (typeof setCheckedSubscription === 'function') {
          if (checkedSubscription?.id === subscription?.id) {
            setCheckedSubscription(null)
          } else {
            setCheckedSubscription(subscription)
          }
        }
      }}
      checked={checkedSubscription?.id === subscription?.id}
      disabled={subscription.status !== 'active'}
    />}
    <div style={{ display: 'flex', flexFlow: 'column', width: '80%' }}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <span><span style={{ fontWeight: '600' }}>{packageName}</span>: {valabilityInterval}</span>
        <span style={{ fontWeight: '600' }}>{status}</span>
      </div>
      <span style={{ opacity: '0.75' }}>
        <span style={{ fontWeight: 600 }}>{brand}</span>
        {' ' + model}
      </span>
      <span style={{ opacity: '0.75' }}>
        <span style={{ fontWeight: 600 }}>{owner}</span>
        {' ' + user}
      </span>
    </div>
    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
      <button
        style={iconStyle}
        className='btn btn-icon btn-bg-light btn-active-color-primary'
        type='button'
        onClick={handleSelect}
      >
        <KTSVG path='/media/icons/duotone/General/Search.svg' className='svg-icon-2' />
      </button>
    </div>
  </div>
}

const getFieldLabel = (label: string, subsCount: number) => {
  let fieldLabel = label || 'Subscriptions found'
  if (subsCount !== 0) {
    fieldLabel += ` (${subsCount})`
  }
  return fieldLabel
}

const SubscriptionsDisplayField = ({ 
  subscriptions, 
  field, 
  onChangeLabel, 
  showCheckbox,
  checkedSubscription, 
  setCheckedSubscription 
} : { 
  subscriptions: SubscriptionType[], 
  field: FieldType, 
  onChangeLabel?: (value: string) => void,
  showCheckbox?: boolean,
  checkedSubscription?: SubscriptionType, 
  setCheckedSubscription?: (checkedSubscription: SubscriptionType) => void 
}) => {
  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionType | null>(null)
  const modalVisible = Boolean(selectedSubscription)
  const setModalVisibleFalse = useCallback((_) => setSelectedSubscription(null), [])
  const fieldLabel = getFieldLabel(field?.label, subscriptions.length)

  return (
    <div>
      {modalVisible &&
        <DisplaySubscriptionModal modalVisible={modalVisible} setModalVisible={setModalVisibleFalse} subscription={selectedSubscription} />
      }
      <EditableLabel label={fieldLabel} onChange={onChangeLabel} isRequired={false} />
      <div style={{ minHeight: '100px', maxHeight: '200px', overflow: 'auto', border: '1px solid #E4E6EF', borderRadius: '0.475rem' }}>
        {(subscriptions || []).map((subscription: SubscriptionType, index: number) => {
          return <SubscriptionRow subscription={subscription} showCheckbox={showCheckbox} checkedSubscription={checkedSubscription} setCheckedSubscription={setCheckedSubscription} handleSelect={(_) => setSelectedSubscription(subscription)} index={index} />
        })}
      </div>
    </div>
  )
}

export default SubscriptionsDisplayField