import { useContext, useEffect, useState } from 'react'
import { CompanyType, ConglomerateCompaniesType } from '../../../../types/company.types'
import InputField from '../../../../_metronic/layout/components/fields/input/input-field'
import Checkbox from '../../../../_metronic/layout/components/fields/checkbox/checkbox'
import { anyToBoolean } from '../../../../utils/string.utils'
import { UserRole } from '../../../../context/user.context'
import { CompanyContext } from '../../../../context/company.context'
import Select from '../../../../_metronic/layout/components/select/Select'

type FieldType = {
  label: string,
  internal_id?: string,
  backend_property?: string,
  visible?: boolean,
  readOnly?: boolean,
  required?: boolean,
  inputType?: string,
  width?: number,
  hideLabel?: boolean,
  checkboxType?: string,
  name?: string,
  type?: string,
  scope?: string,
}


export const DEFAULT_FIELDS: FieldType[] = [
  {
    label: 'Name',
    backend_property: 'name',
    readOnly: false,
    required: true,
  },
  {
    label: 'Phone',
    backend_property: 'phone',
    readOnly: false,
    required: true,
  },
  {
    label: 'Email',
    backend_property: 'email',
    readOnly: false,
    required: true,
  },
  {
    label: 'Adress',
    backend_property: 'address',
    readOnly: false,
    required: true,
  },
  {
    label: 'Contact',
    backend_property: 'contact',
    visible: true,
    readOnly: false,
    required: true,
  },
  {
    label: 'Conglomerat:',
    backend_property: 'company_conglomerate_id',
    visible: true,
    readOnly: false,
    required: true,
    type: 'select',
    scope: 'conglomerate',
  },
  {
    label: 'Stripe Payable',
    name: 'stripe_payable',
    internal_id: 'stripe_payable',
    backend_property: 'stripe_payable',
    visible: true,
    readOnly: false,
    required: false,
    type: 'checkbox',
    checkboxType: 'column'
  },
  {
    label: 'Requires VIN',
    internal_id: 'is_vin_required',
    backend_property: 'is_vin_required',
    visible: true,
    readOnly: false,
    type: 'checkbox',
    checkboxType: 'column',
    hideLabel: false
  },
  {
    label: 'Business profile',
    internal_id: 'business_profile',
    backend_property: 'business_profile',
    visible: true,
    readOnly: false,
    type: 'checkbox',
    checkboxType: 'column',
    hideLabel: false
  },
  {
    label: 'Business profile Id',
    internal_id: 'business_profile_id',
    backend_property: 'business_profile_id',
    visible: false,
    readOnly: false,
    required: false,
    inputType: "hidden",
    hideLabel: true
  },
]

const CompanyInfoComponent = ({
  company,
  setCompany,
  userRole,
  businessProfileId
}: {
  company: CompanyType,
  setCompany: Function,
  userRole: UserRole,
  businessProfileId: number
}) => {
  const [defaultFieldsDynamic, setDefaultFieldsDynamic] = useState([...DEFAULT_FIELDS]);
  const businessCompanyField = defaultFieldsDynamic.find(defField => defField.internal_id === "business_profile");
  const isAdminFleet = (userRole === UserRole.AdminFleet)
  const { conglomerateCompanies } = useContext<any>(CompanyContext);

  useEffect(() => {
    // show/hide the fields for branch/company or for AdminFleet, because it doesn't have access to the subscriptions and it has already a businessProfile on the user
    if (isAdminFleet) {
      setCompany((prevCompany: CompanyType) => ({
        ...prevCompany,
        business_profile: true,
        business_profile_id: businessProfileId,
      }))
    }
    if (company.type !== 'branch' && !isAdminFleet) {
      return;
    }
    const optionalFields = ["stripe_payable", "business_profile", "business_profile_id"]
    setDefaultFieldsDynamic((oldFields: FieldType[]) => {
      return oldFields.map(field => {
        if (optionalFields.includes(field.internal_id)) {
          return { ...field, visible: false, inputType: 'hidden', hideLabel: true, required: false }
        }
        return field
      })
    })
  }, [])

  useEffect(() => {
    if (company.type === 'branch' || isAdminFleet) {
      return;
    }
    // enable and disable the business profile id field
    setDefaultFieldsDynamic((oldFields: FieldType[]) => {
      return oldFields.map(field => {
        if (field.internal_id !== "business_profile_id") {
          return field
        }

        if (company.type !== 'branch' && (company[businessCompanyField.backend_property] == 1) || company[businessCompanyField.backend_property] == 58) {
          return { ...field, visible: true, inputType: 'number', hideLabel: false, required: true }
        } else {
          return { ...field, visible: false, inputType: 'hidden', hideLabel: true, required: false }
        }
      })
    })
  }, [company[businessCompanyField.backend_property]])

  return (
    <div
      // @ts-ignore
      className={`card${false ? ' card-options-active' : ''} draggable has-card-options`}
      style={{ marginTop: 16 }}
    >
      <h1 className='py-10 px-10 d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
        Company Information
      </h1>
      <ul className='autonom-dynamic-card'>
        {defaultFieldsDynamic.map((field) => {
          if (field?.type === 'checkbox' && field?.visible) {
            // field?.visible => the hidden functionality is already implemented below
            return (
              <div key={field?.label} style={{ width: '30%' }}>
                <Checkbox
                  key={field?.backend_property}
                  field={field}
                  value={anyToBoolean(company[field.backend_property])}
                  onChange={(ev) => {
                    let value = ev.target.checked;
                    setCompany((prevCompany: CompanyType) => ({
                      ...prevCompany,
                      [field.backend_property]: value
                    }))
                  }}
                />
              </div>
            )
          }
          if (field?.type === 'select' && field?.scope === 'conglomerate') {
            return (
              <Select
                key={field?.backend_property}
                field={field}
                label={field.label}
                required
                disableBolder
                disablePadding
                options={conglomerateCompanies}
                getOptionLabel={(company: ConglomerateCompaniesType) => company.name}
                getOptionValue={(company: ConglomerateCompaniesType) => company.id}
                value={conglomerateCompanies?.find((cc: ConglomerateCompaniesType) => cc.id === company[field.backend_property])}
                onChange={(value: ConglomerateCompaniesType) => {
                  setCompany((prevState: CompanyType) => ({...prevState, [field.backend_property]: value?.id}))
                }}
            />)
          }
          return <div key={field?.label} style={{ width: field.width ?? '30%' }}>
            <InputField
              key={field?.label}
              value={company[field.backend_property]}
              hideLabel={field.hideLabel ?? false}
              field={field}
              readOnly={field?.readOnly}
              onChange={(ev) => {
                let value = field.inputType !== "checkbox" ? ev.target.value : ev.currentTarget.checked;
                setCompany(prevState => ({
                  ...prevState,
                  [field.backend_property]: value
                }))
              }}
            />
          </div>
        })
        }
      </ul >
    </div >
  )
}

export default CompanyInfoComponent
