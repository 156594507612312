import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { LoginModel } from '../app/modules/auth/models/AuthModel';
import { UserModel } from '../app/modules/auth/models/UserModel';
import { useLocalStorage } from '../hooks/useLocalStorage';
import {
  forgotPasswordRequest,
  getCompanyRequest,
  loginRequest,
  resetPasswordRequest,
} from '../setup/axios/auth.request';
import { AppContext } from './app.context';
import { toast } from 'react-toastify';
import {
  convertUserTypes,
  permissionsForAdmins,
  permissionsForFleetAdmins,
  permissionsForSubscriptionAdmins,
  permissionsForSubscriptionUsers,
  PermissionsType,
} from '../utils/permissions.utils';
import { APIResponseType } from '../types/general.types';
import { anyToBoolean } from '../utils/string.utils';
import { getArrayOfUniqueElems } from '../utils/state.utils';
import { UsersContextType } from '../types/user.types';
import { AppContextType } from '../types/app.types';

export enum UserRole {
  Admin = 'admin',
  AdminSubscriptions = 'admin-subscriptions',
  AdminFleet = 'admin-fleet',
  Operator = 'operator',
  Company = 'company',
  Agent = 'agent',
  Insurance = 'insurance',
}
export const rolesList = Object.values(UserRole).map((role: string) => role);

const UserContext = createContext<UsersContextType>(null);

function UserProvider(props: PropsWithChildren<unknown>) {
  const { setLoading } = useContext<AppContextType>(AppContext);
  const [jwt, setJwt] = useLocalStorage<string | null>('jwt', null);
  const [user, setUser] = useLocalStorage<UserModel | null>('user', null);
  const [mainCompanyName, setMainCompanyName] = useLocalStorage<string | null>(
    'mainCompanyName',
    null
  );
  const [permissions, setPermissions] = useLocalStorage<PermissionsType>('permissions', null);
  const [cookie, setCookie] = useLocalStorage<string | null>('cookie', null);
  const [businessProfileId, setBusinessProfileId] = useLocalStorage<string | null>(
    'businessProfileId',
    null
  );

  const login = async (data: LoginModel) => {
    setLoading(true);
    const response = await loginRequest({ email: data.email, password: data.password });
    setLoading(false);

    if (!response || response.error) {
      toast.error(response.message || 'We have some error in your last request!');
      return { message: response.message, error: true };
    }

    const futureUser = response.user;
    if (futureUser?.roles) {
      futureUser.role = futureUser.roles[0];
    }

    futureUser.from_easytrack = anyToBoolean(futureUser?.from_easytrack);
    futureUser.easytrack = response.easytrack;

    // Set fake avatar;
    futureUser.avatar = futureUser.avatar ? futureUser.avatar : '/media/avatars/blank.jpg';
    setJwt(response.token);
    setUser(futureUser);
    const userRole = futureUser?.role?.slug;
    let businessProfileId = null;
    let permissions: PermissionsType = {
      allowed: [],
      restricted: [],
    };
    let cookie = null;
    if (futureUser.from_easytrack) {
      businessProfileId = response.easytrack.businessProfile.id;
      permissions = convertUserTypes(response.easytrack.userTypes);
      cookie = response.easytrack.cookie;
      // disallow any routes that are strictly for subscription users
      if (userRole !== UserRole.Admin && userRole !== UserRole.AdminSubscriptions) {
        permissions.restricted = permissions.restricted.concat(
          permissionsForSubscriptionUsers.allowed
        );
        permissions.restricted = permissions.restricted.concat(
          permissionsForAdmins.allowed
        )
      }
    } else if (userRole === UserRole.Agent || userRole === UserRole.Company || userRole === UserRole.Insurance) {
      // subscription users
      permissions = { ...permissionsForSubscriptionUsers };
    }
    if (userRole === UserRole.Admin || userRole === UserRole.AdminSubscriptions) {
      permissions.allowed = [
        ...permissions.allowed,
        ...permissionsForAdmins.allowed,
        ...permissionsForFleetAdmins.allowed,
        ...permissionsForSubscriptionUsers.allowed,
        ...permissionsForSubscriptionAdmins.allowed,
      ];
    } else if (userRole === UserRole.AdminFleet) {
      permissions.allowed = [...permissions.allowed, ...permissionsForFleetAdmins.allowed];
    } 
    permissions.allowed = getArrayOfUniqueElems(permissions.allowed);
    permissions.restricted = getArrayOfUniqueElems(permissions.restricted);
    setBusinessProfileId(businessProfileId);
    setPermissions(permissions);
    setCookie(cookie);

    // Set company name
    if (futureUser?.main_company_name) {
      setMainCompanyName(futureUser?.main_company_name);
    } else if (futureUser?.company) {
      const company = futureUser?.company;
      if (!company.main_company_id) {
        setMainCompanyName(company.name || '');
      } else {
        const response: APIResponseType = await getCompanyRequest(company.main_company_id);

        const futureMainCompanyName = response?.company?.name;
        if (!futureMainCompanyName || response.error) {
          return { message: response?.message || '', error: true };
        }

        setMainCompanyName(futureMainCompanyName);
      }
    }

    return { message: 'Welcome back', error: false };
  };

  const forgotPassword = async (email: string) => {
    setLoading(true);

    const response = await forgotPasswordRequest({ email });
    setLoading(false);

    if (!response || response.error) {
      return { message: response?.message || '', error: true };
    }

    // Set fake avatar;
    return { message: 'Email sent', error: false };
  };

  const resetPassword = async (data: { password: string; token: string }) => {
    setLoading(true);

    const response = await resetPasswordRequest(data);
    setLoading(false);

    if (!response || response.error) {
      toast.error(response?.message || 'We have some problems with your last request!');
      return { message: response?.message || '', error: true };
    }

    // Set fake avatar;
    response.user.avatar = '/media/avatars/blank.jpg';
    setJwt(response.token);
    setUser(response.user);
    // Set fake avatar;

    return { message: 'Password reseted', error: false };
  };

  const deleteAccount = () => {
    alert('Please contact administrator!');
  };
  const signout = () => {
    setUser(null);
    setPermissions(null);
    setJwt(null);
    setBusinessProfileId(null);
    setCookie(null);
  };

  const isLogged = () => user != null && (businessProfileId != null || !user?.from_easytrack);

  const store = {
    mainCompanyName,
    user,
    permissions,
    isLogged,
    setUser,
    login,
    jwt,
    businessProfileId,
    cookie,
    signout,
    deleteAccount,
    forgotPassword,
    resetPassword,
  };

  const storeForProvider = useMemo(() => store, [store]);
  return <UserContext.Provider value={storeForProvider}>{props.children}</UserContext.Provider>;
}

export { UserContext };
export default UserProvider;
